import CompressPdfIcon from 'assets/converterIcons/compress_pdf.svg';
import DeletePagesPdfIcon from 'assets/converterIcons/delete-pages-icon.svg';
import EditPdfIcon from 'assets/converterIcons/edit-pdf-icon.svg';
import Excel2PdfIcon from 'assets/converterIcons/excel_2_pdf.svg';
import FromIcon from 'assets/converterIcons/form.svg';
import Image2TextIcon from 'assets/converterIcons/image_to_text.svg';
import {
  default as Jpg2PdfIcon,
  default as Png2PdfIcon,
} from 'assets/converterIcons/jpg_2_pdf.svg';
import MergePdfIcon from 'assets/converterIcons/merge_pdf.svg';
import Pdf2JpgIcon from 'assets/converterIcons/pdf_2_jpg.svg';
import Pdf2PngIcon from 'assets/converterIcons/pdf_2_png.svg';
import Pdf2PptxIcon from 'assets/converterIcons/pdf_2_pptx.svg';
import Pdf2WordIcon from 'assets/converterIcons/pdf_2_word.svg';
import Pdf2XlsIcon from 'assets/converterIcons/pdf_2_xls.svg';
import PdfOcrIcon from 'assets/converterIcons/pdf_ocr.svg';
import Pptx2PdfIcon from 'assets/converterIcons/pptx_2_pdf.svg';
import SignPdfIcon from 'assets/converterIcons/sign-pdf-icon.svg';
import SplitPdfIcon from 'assets/converterIcons/split_pdf.svg';
import Word2PdfIcon from 'assets/converterIcons/word_2_pdf.svg';

export const quickActionsWithGroups = {
  convertFromPDF: [
    {
      path: '/pdf-to-word',
      name: 'landing.pdf_to_word.name',
      icon: Pdf2WordIcon,
    },
    {
      path: '/pdf-to-png',
      name: 'landing.pdf_to_png.name',
      icon: Pdf2PngIcon,
    },
    {
      path: '/pdf-to-jpg',
      name: 'landing.pdf_to_jpg.name',
      icon: Pdf2JpgIcon,
    },
    {
      path: '/pdf-to-excel',
      name: 'landing.pdf_to_excel.name',
      icon: Pdf2XlsIcon,
    },
    {
      path: '/pdf-to-pptx',
      name: 'landing.pdf_to_pptx.name',
      icon: Pdf2PptxIcon,
    },
  ],
  convertToPDF: [
    {
      path: '/word-to-pdf',
      name: 'landing.word_to_pdf.name',
      icon: Word2PdfIcon,
    },
    {
      path: '/png-to-pdf',
      name: 'landing.png_to_pdf.name',
      icon: Png2PdfIcon,
    },
    {
      path: '/jpg-to-pdf',
      name: 'landing.jpg_to_pdf.name',
      icon: Jpg2PdfIcon,
    },
    {
      path: '/excel-to-pdf',
      name: 'landing.excel_to_pdf.name',
      icon: Excel2PdfIcon,
    },
    {
      path: '/pptx-to-pdf',
      name: 'landing.pptx_to_pdf.name',
      icon: Pptx2PdfIcon,
    },
  ],
  editPdf: [
    {
      path: '/edit-pdf',
      name: 'landing.edit_pdf.name',
      icon: EditPdfIcon,
    },
    {
      path: '/merge-pdf',
      name: 'landing.merge_pdf.name',
      icon: MergePdfIcon,
    },
    {
      path: '/merge-images',
      name: 'landing.merge_images.name',
      icon: MergePdfIcon,
    },
    {
      path: '/split-pdf',
      name: 'landing.split_pdf.name',
      icon: SplitPdfIcon,
    },
    {
      path: '/compress-pdf',
      name: 'landing.compress_pdf.name',
      icon: CompressPdfIcon,
    },
    {
      path: '/sign-pdf',
      name: 'landing.sign_pdf.name',
      icon: SignPdfIcon,
    },
    {
      path: '/delete-pages-pdf',
      name: 'landing.delete_pages_pdf.name',
      icon: DeletePagesPdfIcon,
    },
    {
      path: '/ocr-pdf',
      name: 'landing.pdf_ocr.name',
      icon: PdfOcrIcon,
    },
    {
      path: '/image-to-text',
      name: 'landing.image_to_text.name',
      icon: Image2TextIcon,
    },
  ],
  forms: [
    {
      path: '/form-w9',
      name: 'landing.form_w9.name',
      icon: FromIcon,
    },
    {
      path: '/form-ds11',
      name: 'landing.form_ds11.name',
      icon: FromIcon,
    },
    {
      path: '/form-ds82',
      name: 'landing.form_ds82.name',
      icon: FromIcon,
    },
  ],
};

export const mostPopularActions = [
  {
    path: '/edit-pdf',
    name: 'landing.edit_pdf.name',
  },
  {
    path: '/word-to-pdf',
    name: 'landing.word_to_pdf.name',
  },
  {
    path: '/compress-pdf',
    name: 'landing.compress_pdf.name',
  },
  {
    path: '/merge-pdf',
    name: 'landing.merge_pdf.name',
  },
  {
    path: '/merge-images',
    name: 'landing.merge_images.name',
  },
  {
    path: '/split-pdf',
    name: 'landing.split_pdf.name',
  },
];
