import lottie from 'lottie-web/build/player/lottie_light';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import progressFileIconAnimationData from 'assets/animations/progress-file-icon.json';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { updateDownloadProgress } from 'data/actions/documents';
import { uploadProgressSelector } from 'data/selectors/documents';
import { calculateProgress, useAnimatedProgress } from 'hooks/useAnimatedProgress';
import { FileUploadingModalViewTypes } from 'services/analytics/events';
import ModalLayout from '../baseModal';
import { AnimationIcon, Container, ProgressValue, StepsContainer, StepsItem } from './styles';

const ProgressFileOCRModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const downloadProgress = useSelector(uploadProgressSelector());
  const iconRef = useRef<HTMLDivElement>(null);
  const [stage, setStage] = useState(0);
  const animatedProgress = useAnimatedProgress(5);
  const progressValue = calculateProgress(animatedProgress, 0, 90);

  useEffect(() => {
    dispatch(updateDownloadProgress(progressValue));
  }, [dispatch, progressValue]);

  useEffect(() => {
    return () => {
      dispatch(updateDownloadProgress(0));
    };
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => setStage(1), 200);

    setTimeout(() => setStage(2), 1200);

    setTimeout(() => setStage(3), 2600);
  }, []);

  useEffect(() => {
    if (!iconRef.current) return;
    if (typeof window === 'undefined') return;

    const iconAnimationPlayer = lottie.loadAnimation({
      container: iconRef.current,
      renderer: 'svg',
      autoplay: true,
      loop: true,
      animationData: progressFileIconAnimationData,
    });

    // show last frame in loop after complete animation
    iconAnimationPlayer.addEventListener('enterFrame', function (animation) {
      if (animation.currentTime > iconAnimationPlayer.totalFrames - 1) {
        iconAnimationPlayer.playSegments([300, 342]);
        iconAnimationPlayer.setSpeed(0.6);
      }
    });

    return () => {
      iconAnimationPlayer.destroy();
    };
  }, []);

  useEffect(() => {
    dispatch(
      sendAnalyticEvent({
        event: 'file_uploading_modal_view',
        data: {
          type: FileUploadingModalViewTypes.convertFunnels,
        },
      })
    );
  }, [dispatch]);

  return (
    <ModalLayout>
      <Container>
        <AnimationIcon ref={iconRef} />
        <ProgressValue>{Math.floor(downloadProgress)}%</ProgressValue>

        <StepsContainer>
          <StepsItem
            style={{
              opacity: stage > 0 ? 1 : 0,
              transform: stage > 0 ? 'translateY(0)' : 'translateY(100%)',
              transition: 'transform 0.5s ease-in-out',
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <circle cx="8" cy="8.60938" r="8" fill="#3758F9" />
              <path
                d="M10.8962 5.94731C10.8106 5.96083 10.7316 6.00559 10.672 6.07435C9.38077 7.49014 8.20437 8.88865 6.94073 10.2881L5.29127 8.84702C5.25184 8.8125 5.20658 8.78681 5.15808 8.77143C5.10957 8.75605 5.05878 8.75127 5.00859 8.75737C4.95839 8.76347 4.90979 8.78033 4.86556 8.80698C4.82132 8.83363 4.78231 8.86955 4.75077 8.91269C4.71922 8.95584 4.69575 9.00535 4.68169 9.05842C4.66764 9.11149 4.66328 9.16707 4.66886 9.22199C4.67444 9.2769 4.68985 9.33007 4.71421 9.37847C4.73857 9.42686 4.77141 9.46954 4.81085 9.50405L6.73255 11.1861C6.80689 11.2504 6.90032 11.2823 6.99491 11.2759C7.08949 11.2694 7.17855 11.2249 7.245 11.151C8.61945 9.64402 9.85788 8.15535 11.2165 6.66567C11.2746 6.60394 11.3135 6.52369 11.3274 6.43639C11.3414 6.34909 11.3298 6.25919 11.2943 6.17953C11.2587 6.09987 11.2011 6.03453 11.1297 5.99282C11.0582 5.95112 10.9765 5.93519 10.8962 5.94731Z"
                fill="white"
              />
            </svg>
            {t('global.uploading_file_step')}
          </StepsItem>
          <StepsItem
            style={{
              opacity: stage > 1 ? 1 : 0,
              transform: stage > 1 ? 'translateY(0)' : 'translateY(100%)',
              transition: 'transform 0.5s ease-in-out',
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 17"
              fill="none"
            >
              <circle cx="8" cy="8.60938" r="8" fill="#3758F9" />
              <path
                d="M10.8962 5.94731C10.8106 5.96083 10.7316 6.00559 10.672 6.07435C9.38077 7.49014 8.20437 8.88865 6.94073 10.2881L5.29127 8.84702C5.25184 8.8125 5.20658 8.78681 5.15808 8.77143C5.10957 8.75605 5.05878 8.75127 5.00859 8.75737C4.95839 8.76347 4.90979 8.78033 4.86556 8.80698C4.82132 8.83363 4.78231 8.86955 4.75077 8.91269C4.71922 8.95584 4.69575 9.00535 4.68169 9.05842C4.66764 9.11149 4.66328 9.16707 4.66886 9.22199C4.67444 9.2769 4.68985 9.33007 4.71421 9.37847C4.73857 9.42686 4.77141 9.46954 4.81085 9.50405L6.73255 11.1861C6.80689 11.2504 6.90032 11.2823 6.99491 11.2759C7.08949 11.2694 7.17855 11.2249 7.245 11.151C8.61945 9.64402 9.85788 8.15535 11.2165 6.66567C11.2746 6.60394 11.3135 6.52369 11.3274 6.43639C11.3414 6.34909 11.3298 6.25919 11.2943 6.17953C11.2587 6.09987 11.2011 6.03453 11.1297 5.99282C11.0582 5.95112 10.9765 5.93519 10.8962 5.94731Z"
                fill="white"
              />
            </svg>
            {t('global.extracting_text_from_file_step')}
          </StepsItem>
          <StepsItem
            style={{
              opacity: stage > 2 ? 1 : 0,
              transform: stage > 2 ? 'translateY(0)' : 'translateY(100%)',
              transition: 'transform 0.5s ease-in-out',
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 17"
              fill="none"
            >
              <circle cx="8" cy="8.60938" r="8" fill="#3758F9" />
              <path
                d="M10.8962 5.94731C10.8106 5.96083 10.7316 6.00559 10.672 6.07435C9.38077 7.49014 8.20437 8.88865 6.94073 10.2881L5.29127 8.84702C5.25184 8.8125 5.20658 8.78681 5.15808 8.77143C5.10957 8.75605 5.05878 8.75127 5.00859 8.75737C4.95839 8.76347 4.90979 8.78033 4.86556 8.80698C4.82132 8.83363 4.78231 8.86955 4.75077 8.91269C4.71922 8.95584 4.69575 9.00535 4.68169 9.05842C4.66764 9.11149 4.66328 9.16707 4.66886 9.22199C4.67444 9.2769 4.68985 9.33007 4.71421 9.37847C4.73857 9.42686 4.77141 9.46954 4.81085 9.50405L6.73255 11.1861C6.80689 11.2504 6.90032 11.2823 6.99491 11.2759C7.08949 11.2694 7.17855 11.2249 7.245 11.151C8.61945 9.64402 9.85788 8.15535 11.2165 6.66567C11.2746 6.60394 11.3135 6.52369 11.3274 6.43639C11.3414 6.34909 11.3298 6.25919 11.2943 6.17953C11.2587 6.09987 11.2011 6.03453 11.1297 5.99282C11.0582 5.95112 10.9765 5.93519 10.8962 5.94731Z"
                fill="white"
              />
            </svg>
            {t('global.completing_step')}
          </StepsItem>
        </StepsContainer>
      </Container>
    </ModalLayout>
  );
};

export default ProgressFileOCRModal;
