//** Icons */
import buttIcon from 'assets/butt.svg';
import cubeIcon from 'assets/cube.svg';
import profileStarIcon from 'assets/profile-star.svg';
import { IPlan } from 'ts/interfaces/plan/plan';

enum EPlanSlug {
  MONTHLY_0_95_PL_TR = 'the_best_pdf_monthly_39_95_with_trial_0_95_local_PL_TR',
  MONTHLY_1_95_PL_TR = 'the_best_pdf_monthly_39_95_with_trial_1_95_local_PL_TR',
  YEARLY_199_PL_TR = 'the_best_pdf_yearly_199_00_no_trial_local_PL_TR',
  MONTHLY_14_DAYS_TRIAL = 'the_best_pdf_monthly_39_95_with_trial_1_95_local_PL_TR_14_days',

  MONTHLY_24_95_NO_TRIAL = 'the_best_pdf_monthly_24_95_without_trial',

  MONTHLY_0_95_LATAM = 'the_best_pdf_monthly_Latam_29_95_with_trial_0_95',
  MONTHLY_1_95_LATAM = 'the_best_pdf_monthly_Latam_29_95_with_trial_1_95',
  YEARLY_199_LATAM = 'the_best_pdf_yearly_Latam_159_00_no_trial',
  MONTHLY_14_DAYS_TRIAL_LATAM = 'the_best_pdf_monthly_Latam_29_95_with_trial_1_95_14_days',

  MONTHLY_0_95_JPY = 'TheBestPDF_monthly_39_95_in_Japan_with_trial_0_99_JPY',
  MONTHLY_1_95_JPY = 'TheBestPDF_monthly_39_95_in_Japan_with_trial_1_99_JPY',
  YEARLY_199_JPY = 'TheBestPDF_yearly_199_00_in_Japan_no_trial_JPY',
  MONTHLY_14_DAYS_JPY = 'TheBestPDF_monthly_39_95_in_Japan_with_trial_1_99_JPY_14_days',
}

export const initialPlansRecurrentPrice: IPlan[] = [
  {
    id: 'c2ad7560-dc06-42aa-8ad3-a04281e37b68',
    name: 'choose_plan_page.plans_list.0.name',
    testBillingName: 'choose_plan_page.plans_list.0.testBillingName',
    testName: 'choose_plan_page.plans_list.0.testName',
    subtitle: 'choose_plan_page.plans_list.0.subtitle',
    slagName: EPlanSlug.MONTHLY_0_95_PL_TR,
    price: 0.95,
    fullPrice: 39.95,
    currencySymbol: '$',
    period: 'choose_plan_page.plans_list.0.period',
    features: [
      'choose_plan_page.plans_list.0.features.0',
      'choose_plan_page.plans_list.0.features.1',
      'choose_plan_page.plans_list.0.features.2',
      'choose_plan_page.plans_list.0.features.3',
      'choose_plan_page.plans_list.0.features.4',
    ],
    testPeriod: '',
    testPeriodShort: '',
    icon: cubeIcon,
    billingCycle: 'month',
    currency: 'USD',
    descriptionSales: 'choose_plan_page.plans_list.0.descriptionSales',
    descriptionPayment: 'choose_plan_page.plans_list.0.descriptionPayment.consent_sentence.part_1',
  },
  {
    id: 'd80eb757-6332-4a64-a066-7477f164dc58',
    name: 'choose_plan_page.plans_list.1.name',
    testBillingName: 'choose_plan_page.plans_list.1.testBillingName',
    testName: 'choose_plan_page.plans_list.1.testName',
    subtitle: 'choose_plan_page.plans_list.1.subtitle',
    slagName: EPlanSlug.MONTHLY_1_95_PL_TR,
    price: 1.95,
    fullPrice: 39.95,
    period: 'choose_plan_page.plans_list.1.period',
    features: [
      'choose_plan_page.plans_list.1.features.0',
      'choose_plan_page.plans_list.1.features.1',
      'choose_plan_page.plans_list.1.features.2',
      'choose_plan_page.plans_list.1.features.3',
      'choose_plan_page.plans_list.1.features.4',
    ],
    icon: profileStarIcon,
    testPeriod: '',
    testPeriodShort: '',
    mostPopular: true,
    billingCycle: 'month',
    currency: 'USD',
    currencySymbol: '$',
    descriptionSales: 'choose_plan_page.plans_list.1.descriptionSales',
    descriptionPayment: 'choose_plan_page.plans_list.1.descriptionPayment.consent_sentence.part_1',
  },
  {
    id: '6daad4e5-b6d4-44e5-b8ba-68b0d9fbbba5',
    name: 'choose_plan_page.plans_list.2.name',
    testBillingName: 'choose_plan_page.plans_list.2.testBillingName',
    testName: 'choose_plan_page.plans_list.2.testName',
    subtitle: 'choose_plan_page.plans_list.2.subtitle',
    slagName: EPlanSlug.YEARLY_199_PL_TR,
    price: 16.58,
    period: 'choose_plan_page.plans_list.2.period',
    features: [
      'choose_plan_page.plans_list.2.features.0',
      'choose_plan_page.plans_list.2.features.1',
      'choose_plan_page.plans_list.2.features.2',
      'choose_plan_page.plans_list.2.features.3',
      'choose_plan_page.plans_list.2.features.4',
    ],
    testPeriod: 'choose_plan_page.plans_list.2.testPeriod',
    testPeriodShort: 'choose_plan_page.plans_list.2.testPeriodShort',
    icon: buttIcon,
    billingCycle: 'year',
    fullPrice: 199.0,
    isYear: true,
    currency: 'USD',
    currencySymbol: '$',
    descriptionSales: 'choose_plan_page.plans_list.2.descriptionSales',
    descriptionPayment: 'choose_plan_page.plans_list.2.descriptionPayment.consent_sentence.part_1',
  },
];

const initialPlansLongTrial: Partial<IPlan>[] = [
  {
    id: 'c2ad7560-dc06-42aa-8ad3-a04281e37b68',
    name: 'choose_plan_page.plans_list.1.name',
    testBillingName: 'choose_plan_page.plans_list.1.testBillingName',
    testName: 'choose_plan_page.plans_list.1.testName',
    subtitle: 'choose_plan_page.plans_list.1.subtitle',
    slagName: EPlanSlug.MONTHLY_0_95_PL_TR,
    price: 0.95,
    fullPrice: 39.95,
    features: [
      'choose_plan_page.plans_list.1.features.0',
      'choose_plan_page.plans_list.1.features.1',
      'choose_plan_page.plans_list.1.features.2',
      'choose_plan_page.plans_list.1.features.3',
      'choose_plan_page.plans_list.1.features.4',
    ],
  },
  {
    id: 'fec63da7-eafe-4d35-b11d-fcf5c7d728fc',
    name: 'choose_plan_page.plans_list.1.nameLongTrial',
    testBillingName: 'choose_plan_page.plans_list.1.testBillingNameLongTrial',
    testName: 'choose_plan_page.plans_list.1.testName',
    subtitle: 'choose_plan_page.plans_list.1.subtitle',
    slagName: EPlanSlug.MONTHLY_14_DAYS_TRIAL,
    period: 'choose_plan_page.plans_list.1.periodLongTrial',
    descriptionSales: 'choose_plan_page.plans_list.1.descriptionSalesLongTrial',
    descriptionPayment: 'choose_plan_page.plans_list.1.descriptionPaymentLongTrial.consent_sentence.part_1',
  },
  {
    id: '6daad4e5-b6d4-44e5-b8ba-68b0d9fbbba5',
  },
];

const initialLatAmPlans: Partial<IPlan>[] = [
  {
    id: '8d282e69-4c88-4519-a6ce-2ea1063f3ac4',
    slagName: EPlanSlug.MONTHLY_0_95_LATAM,
    fullPrice: 39.95,
  },
  {
    id: '15dc5927-67da-42cd-a041-5c2a05e577c5',
    slagName: EPlanSlug.MONTHLY_1_95_LATAM,
    fullPrice: 39.95,
  },
  {
    id: 'c6f42133-14ec-438b-b591-4fc49e4bf9e7',
    slagName: EPlanSlug.YEARLY_199_LATAM,
    price: 16.58,
    fullPrice: 199.0,
  },
];

const initialPlansLongTrialLatam: Partial<IPlan>[] = [
  {
    id: '8d282e69-4c88-4519-a6ce-2ea1063f3ac4',
    slagName: EPlanSlug.MONTHLY_0_95_LATAM,
    name: 'choose_plan_page.plans_list.1.name',
    testBillingName: 'choose_plan_page.plans_list.1.testBillingName',
    testName: 'choose_plan_page.plans_list.1.testName',
    subtitle: 'choose_plan_page.plans_list.1.subtitle',
    price: 0.95,
    fullPrice: 39.95,
    features: [
      'choose_plan_page.plans_list.1.features.0',
      'choose_plan_page.plans_list.1.features.1',
      'choose_plan_page.plans_list.1.features.2',
      'choose_plan_page.plans_list.1.features.3',
      'choose_plan_page.plans_list.1.features.4',
    ],
  },
  {
    id: 'e6b1eac4-dc04-4d68-a805-f6ee8802a144',
    name: 'choose_plan_page.plans_list.1.nameLongTrial',
    testBillingName: 'choose_plan_page.plans_list.1.testBillingNameLongTrial',
    testName: 'choose_plan_page.plans_list.1.testName',
    subtitle: 'choose_plan_page.plans_list.1.subtitle',
    slagName: EPlanSlug.MONTHLY_14_DAYS_TRIAL_LATAM,
    period: 'choose_plan_page.plans_list.1.periodLongTrial',
    descriptionSales: 'choose_plan_page.plans_list.1.descriptionSalesLongTrial',
    descriptionPayment: 'choose_plan_page.plans_list.1.descriptionPaymentLongTrial.consent_sentence.part_1',
  },
  {
    id: 'c6f42133-14ec-438b-b591-4fc49e4bf9e7',
    slagName: EPlanSlug.YEARLY_199_LATAM,
    price: 16.58,
    fullPrice: 199.0,
  },
];

const initialJapanesePlans: Partial<IPlan>[] = [
  {
    id: '862db0b2-7244-44d5-946d-2d1f2226de46',
    slagName: EPlanSlug.MONTHLY_0_95_JPY,
    price: 0.95,
    fullPrice: 39.95,
    currencySymbol: '円',
    currency: 'JPY',
  },
  {
    id: '15dc5927-67da-42cd-a041-5c2a05e577c5',
    slagName: EPlanSlug.MONTHLY_0_95_JPY,
    price: 1.95,
    fullPrice: 39.95,
    currency: 'JPY',
    currencySymbol: '円',
  },
  {
    id: '42c573ba-7a76-4a40-8855-1b28f4934bf4',
    slagName: EPlanSlug.YEARLY_199_JPY,
    price: 16.58,
    currency: 'JPY',
    currencySymbol: '円',
  },
];

const initialPlansLongTrialJapanese: Partial<IPlan>[] = [
  {
    id: '862db0b2-7244-44d5-946d-2d1f2226de46',
    slagName: EPlanSlug.MONTHLY_0_95_JPY,
    name: 'choose_plan_page.plans_list.1.name',
    testBillingName: 'choose_plan_page.plans_list.1.testBillingName',
    testName: 'choose_plan_page.plans_list.1.testName',
    subtitle: 'choose_plan_page.plans_list.1.subtitle',
    price: 0.95,
    fullPrice: 39.95,
    currency: 'JPY',
    currencySymbol: '円',
    features: [
      'choose_plan_page.plans_list.1.features.0',
      'choose_plan_page.plans_list.1.features.1',
      'choose_plan_page.plans_list.1.features.2',
      'choose_plan_page.plans_list.1.features.3',
      'choose_plan_page.plans_list.1.features.4',
    ],
  },
  {
    id: '1e252aa4-b0db-4483-8cd2-15e4005bb326',
    name: 'choose_plan_page.plans_list.1.nameLongTrial',
    testBillingName: 'choose_plan_page.plans_list.1.testBillingNameLongTrial',
    testName: 'choose_plan_page.plans_list.1.testName',
    subtitle: 'choose_plan_page.plans_list.1.subtitle',
    slagName: EPlanSlug.MONTHLY_14_DAYS_JPY,
    period: 'choose_plan_page.plans_list.1.periodLongTrial',
    descriptionSales: 'choose_plan_page.plans_list.1.descriptionSalesLongTrial',
    descriptionPayment: 'choose_plan_page.plans_list.1.descriptionPaymentLongTrial.consent_sentence.part_1',
    currency: 'JPY',
    currencySymbol: '円',
  },
  {
    id: '42c573ba-7a76-4a40-8855-1b28f4934bf4',
    slagName: EPlanSlug.YEARLY_199_JPY,
    price: 16.58,
    currency: 'JPY',
    currencySymbol: '円',
  },
];

export const getInitialPlansLongTrial = (): IPlan[] => {
  return initialPlansLongTrial.map((plan, index) => ({
    ...initialPlansRecurrentPrice[index],
    ...plan,
  }));
};

export const getInitialJapanesePlans = (): IPlan[] => {
  return initialJapanesePlans.map((plan, index) => ({
    ...initialPlansRecurrentPrice[index],
    ...plan,
  }));
};

export const getInitialPlansLongTrialJapanese = (): IPlan[] => {
  return initialPlansLongTrialJapanese.map((plan, index) => ({
    ...initialPlansRecurrentPrice[index],
    ...plan,
  }));
};

export const getInitialLatAmPlans = (): IPlan[] => {
  return initialLatAmPlans.map((plan, index) => ({
    ...initialPlansRecurrentPrice[index],
    ...plan,
  }));
};

export const getInitialPlansLongTrialLatam = (): IPlan[] => {
  return initialPlansLongTrialLatam.map((plan, index) => ({
    ...initialPlansRecurrentPrice[index],
    ...plan,
  }));
};
