import {
  latAmPlansLongTrialSelector,
  latAmPlansSelector,
  plansJapaneseLongTrialSelector,
  plansJapaneseSelector,
  plansLongTrialSelector,
  plansRecurrentSelector,
} from 'data/selectors/subscriptions';
import { isJapanUserSelector, isLatAmUserSelector } from 'data/selectors/user';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LocalStorageProperties } from 'ts/enums/growthbook';
import { IPlan } from 'ts/interfaces/plan/plan';
import { useLongTrials } from './growthBook/useLongTrials';

export const usePlans = (): IPlan[] => {
  const isJapanUser = useSelector(isJapanUserSelector);
  const isLatAmUser = useSelector(isLatAmUserSelector);

  const defaultPlans = useSelector(plansRecurrentSelector);
  const defaultPlansLongTrials = useSelector(plansLongTrialSelector);

  const latAmPlans = useSelector(latAmPlansSelector);
  const latAmPlansLongTrials = useSelector(latAmPlansLongTrialSelector);

  const japanesePlans = useSelector(plansJapaneseSelector);
  const japanesePlansLongTrials = useSelector(plansJapaneseLongTrialSelector);

  const longTrials = useLongTrials();

  const defaultPlansMap = useMemo(
    () => ({
      [LocalStorageProperties.LONG_TRIALS_A]: defaultPlans,
      [LocalStorageProperties.LONG_TRIALS_B]: defaultPlansLongTrials,
    }),
    [defaultPlans, defaultPlansLongTrials]
  );

  const latAmPlansMap = useMemo(
    () => ({
      [LocalStorageProperties.LONG_TRIALS_A]: latAmPlans,
      [LocalStorageProperties.LONG_TRIALS_B]: latAmPlansLongTrials,
    }),
    [latAmPlans, latAmPlansLongTrials]
  );

  const japanesePlansMap = useMemo(
    () => ({
      [LocalStorageProperties.LONG_TRIALS_A]: japanesePlans,
      [LocalStorageProperties.LONG_TRIALS_B]: japanesePlansLongTrials,
    }),
    [japanesePlans, japanesePlansLongTrials]
  );

  return useMemo(() => {
    if (isJapanUser) return japanesePlansMap[longTrials];

    if (isLatAmUser) return latAmPlansMap[longTrials];

    return defaultPlansMap[longTrials];
  }, [isJapanUser, isLatAmUser, japanesePlansMap, longTrials, latAmPlansMap, defaultPlansMap]);
};
