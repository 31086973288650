import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import closeImagePath from 'assets/close-icon.svg';
import errorImgPath from 'assets/errors/error-square.svg';
import ModalLayout from 'components/modals/baseModal';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { toggleModal } from 'data/actions/modals';
import { CloseIcon } from '../baseModal/styles';
import { Container, Description, Image, ImageContainer, Title } from '../paymentErrorModal/styles';

const FileUploadErrorModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(sendAnalyticEvent({ event: 'converting_failed_modal_view' }));
  }, [dispatch]);

  const handleClose = () => {
    dispatch(sendAnalyticEvent({ event: 'converting_failed_close_tap' }));
    dispatch(toggleModal({ visible: false }));
  };

  return (
    <ModalLayout>
      <Container>
        <CloseIcon onClick={handleClose} src={closeImagePath} alt="" />
        <ImageContainer>
          <Image alt="" src={errorImgPath} />
        </ImageContainer>
        <Title>{t('global.upload_failed_error')}</Title>
        <Description>{t('global.please_double_check_file_error')}</Description>
      </Container>
    </ModalLayout>
  );
};

export default FileUploadErrorModal;
