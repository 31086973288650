import { FC } from 'react';
import { useSelector } from 'react-redux';

import { isReversedCurrencyPositionSelector, userCurrencySelector } from 'data/selectors/user';

//** Types */
import { IPlan } from 'ts/interfaces/plan/plan';

//** Icons */
import checkMarkIcon from 'assets/checkmark.svg';

//** Styles */
import radioButtonCheckPath from 'assets/radiobutton-check.svg';
import radioButtonPath from 'assets/radiobutton.svg';
import { selectedPlanSelector } from 'data/selectors/subscriptions';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  Container,
  Currency,
  FeatureIcon,
  FeatureItem,
  FeaturesContainer,
  MostPopular,
  MostPopularContainer,
  Period,
  PlanContentWrapper,
  PlanHeader,
  PlanSubtitle,
  PlanTitle,
  PlanTitleContainer,
  Price,
  PriceContainer,
} from './styles';

const PlanItemTestC: FC<{ planData: IPlan; handleSelectPlan?: unknown }> = ({
  planData,
  handleSelectPlan,
}) => {
  const { t } = useTranslation();
  const userCurrency = useSelector(userCurrencySelector);
  const isReversedCurrencyPosition = useSelector(isReversedCurrencyPositionSelector);
  const selectedPlan = useSelector(selectedPlanSelector) || getJsonFomStorage('selectedPlan');
  const handleChoosePlan = (place: string, route?: string) => {
    if (typeof handleSelectPlan === 'function') handleSelectPlan({ plan: planData, route, place });
  };

  return (
    <Container
      current={(selectedPlan?.name === planData?.name)?.toString()}
      onClick={() => handleChoosePlan('planTab')}
    >
      {planData.mostPopular && (
        <MostPopularContainer>
          <MostPopular>{t('global.most_popular_plan_badge')}</MostPopular>
        </MostPopularContainer>
      )}
      <PlanContentWrapper>
        <PlanHeader>
          <PlanTitle>
            <PlanTitleContainer>
              <span>{t(planData?.testBillingName || '')} </span>
              <span>{t(planData?.testName || '')}</span>
            </PlanTitleContainer>
            <LazyLoadImage
              src={selectedPlan?.name === planData?.name ? radioButtonCheckPath : radioButtonPath}
              alt=""
            />
          </PlanTitle>
          <PlanSubtitle>{t(planData?.subtitle || '')}</PlanSubtitle>
        </PlanHeader>

        <PriceContainer>
          {isReversedCurrencyPosition ? (
            <>
              <Price>{planData.price}</Price>
              <Currency>{planData?.currencySymbol || userCurrency}</Currency>
            </>
          ) : (
            <>
              <Currency>{planData?.currencySymbol || userCurrency}</Currency>
              <Price>{planData.price}</Price>
            </>
          )}
          <Period>{t(planData.testPeriod || '')}</Period>
        </PriceContainer>

        <FeaturesContainer>
          {planData.features.map((feature, index: number) => (
            <FeatureItem key={`plan-feature-${index + 1}`}>
              <FeatureIcon src={checkMarkIcon} />
              {t(feature)}
            </FeatureItem>
          ))}
        </FeaturesContainer>
      </PlanContentWrapper>
    </Container>
  );
};

export default PlanItemTestC;
