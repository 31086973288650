import { useTranslation } from 'react-i18next';
import { OcrExportFormatEnum, SupportedOcrExportFormat } from 'ts/interfaces/documents/document';

export interface OCRExportFormat {
  label: string;
  description: string;
  format: SupportedOcrExportFormat;
}

export const useOCRFormats = (): OCRExportFormat[] => {
  const { t } = useTranslation();

  return [
    {
      label: 'DOCX',
      description: t('landing.pdf_ocr.formats.docx'),
      format: OcrExportFormatEnum.docx,
    },
    {
      label: 'PDF',
      description: t('landing.pdf_ocr.formats.pdfSearchable'),
      format: OcrExportFormatEnum.pdfSearchable,
    },
    {
      label: 'TXT',
      description: t('landing.pdf_ocr.formats.txt'),
      format: OcrExportFormatEnum.txt,
    },
  ];
};
