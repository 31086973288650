import { Rate } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleModal } from 'data/actions/modals';
import { trustPilotLinkSelector } from 'data/selectors/user';

import bigStarImgPath from 'assets/big-star-icon.svg';
import closeImagePath from 'assets/close-icon.svg';
import startEmpty from 'assets/star-empty-icon.svg';
import startFilled from 'assets/star-filled-icon.svg';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { getTrustPilotLink } from 'data/actions/user';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';
import useDisableScroll from 'hooks/useDisableScroll';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CloseIcon, ModalContainer } from '../baseModal/styles';
import { BigStarImage, Container, Description, Title } from './styles';

const TrustPilotModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const trustPilotLink = useSelector(trustPilotLinkSelector);
  useDisableScroll();
  useCloseModalOnBack();

  const [rate, setRate] = useState(0);
  const [step, setStep] = useState('rate');

  useEffect(() => {
    dispatch(sendAnalyticEvent({ event: 'rate_us_modal_view' }));

    dispatch(getTrustPilotLink());
  }, [dispatch]);

  const handleChooseRate = (value: number) => {
    setRate(value);

    dispatch(sendAnalyticEvent({ event: 'rate_us_close_modal_view' }));
    dispatch(sendAnalyticEvent({ event: 'rate_us_tap', data: { rate: value, answer: value } }));

    setTimeout(
      () => {
        setStep('thankYou');

        if (value > 3 && trustPilotLink) window.open(trustPilotLink, '_blank');
      },
      window.innerWidth <= 768 ? 600 : 0
    );
  };

  const handleCloseModal = useCallback(() => {
    dispatch(toggleModal({ visible: false }));
    dispatch(
      sendAnalyticEvent({
        event: rate > 0 ? 'rate_us_close_close_tap' : 'rate_us_close_tap',
      })
    );
  }, [rate, dispatch]);

  return (
    <ModalContainer>
      <Container>
        <CloseIcon onClick={handleCloseModal} src={closeImagePath} alt="" />
        <BigStarImage src={bigStarImgPath} alt="big star" />
        {step === 'thankYou' ? (
          <Title>{t('global.thank_feedback')}</Title>
        ) : (
          <>
            <Title>{t('global.rate_us_question')}</Title>
            <Description>{t('global.your_feedback_helpful')}</Description>
            <Rate
              character={(props: any) => {
                if (props.index < props.value) {
                  return <LazyLoadImage src={startFilled} alt="" />;
                }
                return <LazyLoadImage src={startEmpty} alt="" />;
              }}
              value={rate}
              defaultValue={0}
              onChange={handleChooseRate}
            />
          </>
        )}
      </Container>
    </ModalContainer>
  );
};

export default TrustPilotModal;
