import { FC } from 'react';
import { useSelector } from 'react-redux';

import { isReversedCurrencyPositionSelector, userCurrencySelector } from 'data/selectors/user';

//** Types */
import { IPlan } from 'ts/interfaces/plan/plan';

//** Icons */
import checkMarkIcon from 'assets/checkmark.svg';

//** Styles */
import CommonButton from 'components/common/button';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Currency,
  FeatureIcon,
  FeatureItem,
  FeaturesContainer,
  MostPopular,
  MostPopularContainer,
  Period,
  PlanContentWrapper,
  PlanHeader,
  PlanSubtitle,
  PlanTitle,
  PlanTitleContainer,
  Price,
  PriceContainer,
} from './styles';

const PlanItem: FC<{ planData: IPlan; handleSelectPlan?: unknown }> = ({
  planData,
  handleSelectPlan,
}) => {
  const { t } = useTranslation();
  const userCurrency = useSelector(userCurrencySelector);
  const isReversedCurrencyPosition = useSelector(isReversedCurrencyPositionSelector);
  const handleChoosePlan = (place: string, route?: string) => {
    if (typeof handleSelectPlan === 'function') handleSelectPlan({ plan: planData, route, place });
  };

  return (
    <Container mostPopular={planData.mostPopular?.toString()}>
      {planData.mostPopular && (
        <MostPopularContainer>
          <MostPopular>{t('global.most_popular_plan_badge')}</MostPopular>
        </MostPopularContainer>
      )}
      <PlanContentWrapper>
        <PlanHeader>
          <PlanTitle>
            <PlanTitleContainer>
              <span>{t(planData?.testBillingName || '')} </span>
              <span>{t(planData?.testName || '')}</span>
            </PlanTitleContainer>
          </PlanTitle>
          <PlanSubtitle>{t(planData?.subtitle || '')}</PlanSubtitle>
        </PlanHeader>

        <PriceContainer>
          {isReversedCurrencyPosition ? (
            <>
              <Price>{planData.price}</Price>
              <Currency>{planData?.currencySymbol || userCurrency}</Currency>
            </>
          ) : (
            <>
              <Currency>{planData?.currencySymbol || userCurrency}</Currency>
              <Price>{planData.price}</Price>
            </>
          )}
          <Period>{t(planData.testPeriod || '')}</Period>
        </PriceContainer>

        <CommonButton
          onClick={() => handleChoosePlan('button')}
          type={planData.mostPopular ? 'primary' : 'secondary'}
        >
          {t('choose_plan_page.continue_to_checkout_button')}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M18 9.5L11.5312 2.9375C11.25 2.65625 10.8125 2.65625 10.5312 2.9375C10.25 3.21875 10.25 3.65625 10.5312 3.9375L15.7812 9.28125H2.5C2.125 9.28125 1.8125 9.59375 1.8125 9.96875C1.8125 10.3438 2.125 10.6875 2.5 10.6875H15.8437L10.5312 16.0938C10.25 16.375 10.25 16.8125 10.5312 17.0938C10.6562 17.2188 10.8437 17.2813 11.0312 17.2813C11.2187 17.2813 11.4062 17.2188 11.5312 17.0625L18 10.5C18.2812 10.2188 18.2812 9.78125 18 9.5Z"
              fill={planData.mostPopular ? 'white' : '#3758F9'}
            />
          </svg>
        </CommonButton>

        <FeaturesContainer>
          {planData.features.map((feature, index: number) => (
            <FeatureItem key={`plan-feature-${index + 1}`}>
              <FeatureIcon src={checkMarkIcon} />
              {t(feature)}
            </FeatureItem>
          ))}
        </FeaturesContainer>
      </PlanContentWrapper>
    </Container>
  );
};

export default PlanItem;
