import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import closeImagePath from 'assets/close-icon.svg';
import GoogleIcon from 'assets/google.svg';
import sendFileImage from 'assets/send-file-icon.svg';
import CommonButton from 'components/common/button';
import EmailAutoCompleteInput from 'components/common/inputEmailAutoComplete';
import CommonLink from 'components/common/link';
import openNotification from 'components/commonNotification';
import { toggleModal } from 'data/actions/modals';
import { createAnonymousUser, createUserReveal, getUser } from 'data/actions/user';
import { modalOptionsSelector } from 'data/selectors/modals';
import buildOauthRequestUrl from 'helpers/buildOauthRequestUrl';
import { isEmailValid } from 'helpers/validation';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { GoogleButton } from 'layouts/authForm/styles';
import { EModalTypes } from 'ts/enums/modal.types';
import { ENotification } from 'ts/interfaces/common/notification';

import { sendAnalyticEvent } from 'data/actions/analytics';
import useDisableScroll from 'hooks/useDisableScroll';
import { beforeUnloadCallback } from 'utils/beforeUnload';
import { getPrevPage } from 'utils/prevPath';
import { CloseIcon, ModalContainer } from '../../../baseModal/styles';
import { Description, InputContainer, InputsWrapper, LabeledDivider, Title } from '../../styles';
import { Container, ContentWrapper, HeaderContainer } from '../emailModalTestB/styles';

const EnterEmailModalTestC: FC = () => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const { t } = useTranslation();
  useDisableScroll();

  const { handleConvertDocument } = useSelector(modalOptionsSelector);

  const [email, setEmail] = useState<string>('');
  const [errors, setErrors] = useState({ email: '' });

  useEffect(() => {
    dispatch(
      sendAnalyticEvent({
        event: 'email_enter_modal_view',
        data: {
          fromPage: getPrevPage() || '',
          screen_config_name: 'send_file_email_modal_with_google',
        },
      })
    );
  }, [dispatch]);

  const validateEmail = (email: string) => {
    if (!isEmailValid(email)) {
      setErrors((prev) => ({
        ...prev,
        email: t('global.please_enter_valid_email_error'),
      }));
      return false;
    }
    setErrors((prev) => ({ ...prev, email: '' }));
    return true;
  };

  const onFailedSignUp = () => {
    dispatch(
      sendAnalyticEvent({
        event: 'email_already_exist_popup',
      })
    );
    openNotification({
      message: (
        <div>
          {t('auth_form.email_already_exist_error')}
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => dispatch(toggleModal({ visible: true, type: EModalTypes.AUTH_FORM }))}
          >
            {' '}
            {t('auth_form.log_in_button')}
          </span>
        </div>
      ),
      type: ENotification.ERROR,
      duration: 10,
    });
  };

  const onSuccessSignUp = () => {
    const handleNavigate = (route: string) => {
      dispatch(toggleModal({ visible: false }));
      navigate(route);
      window.scrollTo(0, 0); // Scrolls to the top of the page
    };
    window.onbeforeunload = null;

    dispatch(getUser({ onFailed: () => dispatch(createAnonymousUser()) }));

    dispatch(
      sendAnalyticEvent({
        event: 'email_enter_tap',
        data: { email, type: 'email' },
      })
    );

    if (typeof handleConvertDocument === 'function') {
      handleConvertDocument();
      return dispatch(toggleModal({ visible: false }));
    }
    handleNavigate('/app/dashboard');
    dispatch(toggleModal({ visible: false }));
  };

  const handleDownLoadFile = () => {
    const isValidEmail = validateEmail(email);
    if (!isValidEmail) return;

    dispatch(
      createUserReveal({
        email,
        onSuccess: onSuccessSignUp,
        onFailed: onFailedSignUp,
        sendFile: true,
      })
    );
  };

  const connectGmailAccount = (e: any) => {
    window.onbeforeunload = null;
    const url = buildOauthRequestUrl();
    window.location.href = url;
    dispatch(sendAnalyticEvent({ event: 'continue_google_tap' }));
  };

  const handleModalClose = () => {
    dispatch(
      sendAnalyticEvent({
        event: 'enter_email_close_tap',
      })
    );
    window.onbeforeunload = beforeUnloadCallback;
    dispatch(toggleModal({ visible: false }));
  };

  const descriptionElement = useMemo(
    () => (
      <Description>
        {t('auth_form.consent_sentence.0')}{' '}
        <CommonLink size="13.4px" to="/terms" target="_blank">
          {t('auth_form.consent_sentence.1')}
        </CommonLink>
        , {t('auth_form.consent_sentence.2')}{' '}
        <CommonLink size="13.4px" to="/privacy-policy" target="_blank">
          {t('auth_form.consent_sentence.3')}
        </CommonLink>
      </Description>
    ),
    [t]
  );

  return (
    <ModalContainer>
      <Container>
        <HeaderContainer>
          <img src={sendFileImage} alt="send file" />
          <CloseIcon onClick={() => handleModalClose()} src={closeImagePath} alt="" />
        </HeaderContainer>

        <ContentWrapper>
          <Title>{t('global.we_will_send_copy_document')}</Title>

          <GoogleButton onClick={connectGmailAccount}>
            <img src={GoogleIcon} alt="google-icon" />
            {t('auth_form.continue_with_google_button')}
          </GoogleButton>
          <LabeledDivider>
            <span>{t('global.login_method_separator')}</span>
          </LabeledDivider>

          <InputsWrapper>
            <InputContainer>
              <EmailAutoCompleteInput
                onChange={(value: string) => setEmail(value)}
                value={email}
                placeholder={t('auth_form.email_address_input_placeholder')}
                label={t('auth_form.email_address_input_label')}
                error={errors.email}
              />
            </InputContainer>
          </InputsWrapper>

          <CommonButton type="primary" onClick={() => handleDownLoadFile()}>
            {t('global.download_file')}
          </CommonButton>

          {descriptionElement}
        </ContentWrapper>
      </Container>
    </ModalContainer>
  );
};

export default EnterEmailModalTestC;
