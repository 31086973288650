import { createSelector } from 'reselect';

const baseSubscriptionsSelector = (state: any) => state?.subscriptions;

export const selectedPlanSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.selectedPlan
);

export const plansRecurrentSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.plansRecurrent || []
);

export const plansLongTrialSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.plansLongTrial || []
);

export const latAmPlansSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.plansLatAm || []
);

export const latAmPlansLongTrialSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.plansLatAmLongTrial || []
);

export const plansJapaneseSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.plansJapanese || []
);

export const plansJapaneseLongTrialSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.plansJapaneseLongTrial || []
);

export const orderSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.order || {}
);

export const subscriptionOfferProductIdSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.offerPlanTestB?.id || '0095754f-83e1-48a4-a371-95e05b259f13'
);

export const subscriptionOfferSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => subscriptions?.offerPlan || {}
);
