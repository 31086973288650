import axios, { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import openNotification from 'components/commonNotification';
import { sendAnalyticEvent } from 'data/actions/analytics';
import {
  convertDocument,
  convertExistingDocument,
  downloadDocument,
  getConvertedFile,
  getUploadLink,
  setConvertDocumentData,
  updateDownloadProgress,
} from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
import { editDocumentDataSelector } from 'data/selectors/documents';
import {
  isUserAuthenticated,
  userEmailSelector,
  userSubscriptionSelector,
} from 'data/selectors/user';
import { countPdfPages } from 'helpers/countPdfPages';
import { getFileKeyFromAWSLink } from 'helpers/getFileKeyFromAWSLink';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Analytics } from 'services/analytics';
import { GET_RESULT_COUNT_RETRIES, TABLET_SCREEN_SIZE_WIDTH } from 'ts/constants/general';
import { LocalStorageProperties } from 'ts/enums/growthbook';
import { EModalTypes } from 'ts/enums/modal.types';
import { ENotification } from 'ts/interfaces/common/notification';
import { IConvertDocumentDto } from 'ts/interfaces/documents/document';
import { EServiceType, IService } from 'ts/interfaces/services/service';
import { formatFileSize } from 'utils/formatFileSize';
import { useModalSalesPayment } from './growthBook/useModalSalesPayment';
import { ServerErrorResponse } from 'ts/interfaces/common/network';

const useFileUploadAndConvert = ({ service }: { service?: IService }) => {
  const navigate = useCustomNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isAuth = useSelector(isUserAuthenticated);
  const userSubscription = useSelector(userSubscriptionSelector);
  const email = useSelector(userEmailSelector);
  const editDocumentData = useSelector(editDocumentDataSelector());
  const modalSalesPAymentABC = useModalSalesPayment();

  const goToCheckoutCallback = useMemo(
    () => ({
      [LocalStorageProperties.MODAL_SALES_PAYMENT_A]: () => navigate('/choose-plan'),
      [LocalStorageProperties.MODAL_SALES_PAYMENT_B]: () =>
        dispatch(toggleModal({ type: EModalTypes.MODAL_SELECT_PLAN_B, visible: true })),
      [LocalStorageProperties.MODAL_SALES_PAYMENT_C]: () =>
        dispatch(toggleModal({ type: EModalTypes.MODAL_SELECT_PLAN_C, visible: true })),
    }),
    [dispatch, navigate]
  );

  const modalSalesPAymentABCVariant = useMemo(() => {
    if (window.innerWidth <= TABLET_SCREEN_SIZE_WIDTH) {
      return LocalStorageProperties.MODAL_SALES_PAYMENT_A;
    }

    return modalSalesPAymentABC;
  }, [modalSalesPAymentABC]);

  // after upload a file run the progress animation
  const handleUpdateDownloadProgress = () => {
    let progressValue = 90;
    const intervalId = setInterval(() => {
      progressValue += 1;
      dispatch(updateDownloadProgress(progressValue));
      if (progressValue >= 100) clearInterval(intervalId);
    }, 50);
  };

  const analyticsEventUpload = ({
    success,
    size,
    errorCode,
    fileCounter,
    from,
  }: {
    success: boolean;
    size: number;
    from: string;
    errorCode?: number;
    fileCounter?: number;
  }) => {
    dispatch(
      sendAnalyticEvent({
        event: 'file_upload_status',
        data: {
          status: success ? 'success' : 'fail',
          place: 'additional',
          errorCode,
          size: formatFileSize(size),
          accurate_size: size / 1000000,
          fileCounter: fileCounter || 1,
          file_format: `.${from?.toLowerCase() || '.pdf'}`,
          is_validation_error: success ? 'false' : 'true',
        },
      })
    );
  };

  const sendDownloadAgainMessage = (documentId: string) => {
    openNotification({
      message: (
        <div>
          {t('notifications.file_download_started')}{' '}
          <span className="link" onClick={() => dispatch(downloadDocument(documentId))}>
            {t('notifications.download_file_again')}
          </span>
        </div>
      ),
      type: ENotification.INFO,
      duration: 5,
    });
  };

  const handleConvertDocument = (
    dataToConvert: IConvertDocumentDto,
    signUp?: boolean,
    subscription?: any
  ) => {
    if (!userSubscription && !subscription?.id) {
      handleUpdateDownloadProgress();
      return setTimeout(
        () => {
          if (service?.serviceType !== EServiceType.CONVERTOR && editDocumentData?.id) {
            dispatch(
              convertExistingDocument({
                from: dataToConvert.from,
                to: dataToConvert.to,
                fileId: editDocumentData.id,
              })
            );
          } else {
            dispatch(convertDocument(dataToConvert));
          }
          dispatch(toggleModal({ visible: false }));
          goToCheckoutCallback[modalSalesPAymentABCVariant]();
          window.scrollTo(0, 0); // Scrolls to the top of the page
        },
        signUp ? 0 : 1000
      );
    }

    const onSuccess = (res: any) => {
      let countRetries = 0;

      if (dataToConvert.from === 'PDF' && dataToConvert.to === 'PDF' && res.id) {
        dispatch(updateDownloadProgress(100));
        setTimeout(() => {
          dispatch(downloadDocument(res.id));
          dispatch(toggleModal({ visible: false }));
          sendDownloadAgainMessage(res?.id);
        }, 500);
        return;
      }
      const interval = setInterval(() => {
        const onSuccessCallback = (res: any) => {
          handleUpdateDownloadProgress();
          clearInterval(interval);

          // awaiting the downloading animation
          setTimeout(() => {
            if (res?.processing_status === 'FAILED')
              return dispatch(
                toggleModal({
                  type: EModalTypes.FILE_UPLOAD_ERROR,
                  visible: true,
                })
              );

            dispatch(toggleModal({ visible: false }));
            sendDownloadAgainMessage(res?.id);

            dispatch(downloadDocument(res?.id));
          }, 1000);
        };
        countRetries += 1;
        if (countRetries < GET_RESULT_COUNT_RETRIES)
          dispatch(getConvertedFile(res?.fileId, onSuccessCallback));
        else {
          clearInterval(interval);
          dispatch(toggleModal({ visible: true, type: EModalTypes.FILE_UPLOAD_ERROR }));
        }
      }, 1500);
    };

    const onFailed = (error: any) => {
      if (error?.response?.data?.message === 'error.convert.unsupported-formats') {
        dispatch(toggleModal({ visible: false }));
        dispatch(updateDownloadProgress(0));
        return openNotification({
          message: t('global.google_login_error'),
          type: ENotification.ERROR,
          duration: 5,
        });
      }

      goToCheckoutCallback[modalSalesPAymentABCVariant]();
      window.scrollTo(0, 0); // Scrolls to the top of the page
    };

    if (service?.serviceType !== EServiceType.CONVERTOR && editDocumentData?.id) {
      dispatch(
        convertExistingDocument({
          from: dataToConvert.from,
          to: dataToConvert.to,
          fileId: editDocumentData.id,
          onSuccess,
          onFailed,
        })
      );
    } else {
      dispatch(convertDocument(dataToConvert, onSuccess, onFailed));
    }
  };

  const handleConvertFile = (dataToConvert: IConvertDocumentDto) => {
    if (!isAuth) {
      handleUpdateDownloadProgress();
      return setTimeout(() => {
        dispatch(
          toggleModal({
            type: EModalTypes.ENTER_EMAIL_ADDRESS,
            visible: true,
            options: {
              handleConvertDocument: (subscription: any) =>
                handleConvertDocument(dataToConvert, true, subscription),
              signUp: true,
              servicePath: service?.path,
            },
          })
        );
      }, 2000);
    }
    handleConvertDocument(dataToConvert);
  };

  const handleUploadFileByLinkToS3 = async (file: File, uploadLink: string, to?: string) => {
    try {
      Analytics.sendEvent({
        event: 'file_save_modal_toggle',
        data: {
          file_name: file.name,
          file_size: file.size,
        },
      });
      dispatch(updateDownloadProgress(0));
      dispatch(
        toggleModal({
          type: to ? EModalTypes.PROCESSING_DOCUMENT : EModalTypes.PROGRESS_FILE,
          visible: true,
          options: { file },
        })
      );

      const res = await axios.put(uploadLink, file, { headers: {} });
      const from = file?.name?.split('.')?.pop()?.toUpperCase() || service?.from || 'PDF';
      const pagesCount = from === 'PDF' ? await countPdfPages(file) : 1;

      const dataToConvert: IConvertDocumentDto = {
        filename: file.name,
        size: file.size,
        key: getFileKeyFromAWSLink(res?.request?.responseURL),
        url: res?.request?.responseURL,
        pagesCount,
        from,
        to: to || service?.to || 'PNG',
      };

      // awaiting the downloading animation
      setTimeout(() => {
        dispatch(setConvertDocumentData(dataToConvert));

        // set converted data to localStorage and use this data for google auth
        localStorage.setItem(
          'dataToConvert',
          JSON.stringify({
            file: dataToConvert,
            service: service?.path,
            email: email,
            serviceType: service?.serviceType,
          })
        );

        handleConvertFile(dataToConvert);
        analyticsEventUpload({ size: file?.size, success: true, from });
      }, 3000);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  // upload to s3 bucket and convert file
  const handleUploadFile = (file: File, to?: string) => {
    if (!file) return;

    dispatch(
      sendAnalyticEvent({
        event: 'features_tap',
        data: {
          features_name: service?.path?.replace('/', '') || '',
          method: 'click',
        },
      })
    );

    Analytics.sendEvent({
      event: 'file_save_upload_link_start',
      data: {
        file_name: file.name,
        file_size: file.size,
      },
    });

    dispatch(
      getUploadLink({
        filename: file?.name,
        onSuccess: (res: any) => {
          Analytics.sendEvent({
            event: 'file_save_upload_link_success',
            data: {
              file_name: file.name,
              file_url: res[0]?.url,
            },
          });
          handleUploadFileByLinkToS3(file, res[0]?.url, to);
        },
        onFailed: (error?: AxiosError<ServerErrorResponse>) => {
          Analytics.sendEvent({
            event: 'file_save_upload_link_failed',
            data: {
              message: error?.response?.data?.message || error?.message,
              statusCode: error?.response?.data?.statusCode || error?.code,
              headers: error?.config?.headers
            },
          });
        },
        service,
      })
    );
  };

  return {
    handleUploadFile,
    handleConvertDocument,
  };
};

export default useFileUploadAndConvert;
