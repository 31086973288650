import { useFeature } from '@growthbook/growthbook-react';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { GrowthBookTestType, LocalStorageProperties } from 'ts/enums/growthbook';

export const useDiscountSales = (): LocalStorageProperties => {
  const abTests = getJsonFomStorage('abTests');
  const discountTestABC = useFeature(GrowthBookTestType.DISCOUNT_SALES);

  if (abTests?.includes(LocalStorageProperties.DISCOUNT_SALES_B))
    return LocalStorageProperties.DISCOUNT_SALES_B;
  else if (abTests?.includes(LocalStorageProperties.DISCOUNT_SALES_C))
    return LocalStorageProperties.DISCOUNT_SALES_C;

  if (discountTestABC?.experiment?.key && discountTestABC?.experimentResult?.value) {
    return `${discountTestABC?.experiment?.key}_${discountTestABC?.experimentResult?.value}` as LocalStorageProperties;
  }

  return LocalStorageProperties.DISCOUNT_SALES_A;
};
