import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import closeImagePath from 'assets/close-icon.svg';
import radioButtonCheckPath from 'assets/radiobutton-check.svg';
import radioButtonPath from 'assets/radiobutton.svg';
import CommonButton from 'components/common/button';
import CommonInput from 'components/common/input';
import ModalLayout from 'components/modals/baseModal';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector } from 'data/selectors/modals';

import { sendAnalyticEvent } from 'data/actions/analytics';
import { getFileFormatFromFile } from 'helpers/getFileFormatFromFile';
import useFileUploadAndOCR from 'hooks/useFileUploadAndOCR';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { OcrExportFormatEnum, SupportedOcrExportFormat } from 'ts/interfaces/documents/document';
import { OCRExportFormat, useOCRFormats } from '../../../hooks/useOCRFormats';
import { CloseIcon } from '../baseModal/styles';
import {
  ButtonsContainer,
  Container,
  FormatDescription,
  FormatItem,
  FormatLabel,
  FormatsContainer,
  InputContainer,
  Title,
} from './styles';

const EXPORT_FORMATS_FEATURE_NAME_MAP: Record<SupportedOcrExportFormat, string> = {
  [OcrExportFormatEnum.docx]: 'pdf_to_doc',
  [OcrExportFormatEnum.pdfSearchable]: 'pdf_to_searchable',
  [OcrExportFormatEnum.txt]: 'pdf_to_txt',
};

const ChooseFormatAndParseTextModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { service, file } = useSelector(modalOptionsSelector);

  const [filename, setFilename] = useState<string>('');
  const [fileFormat, setFileFormat] = useState<string>('');
  const [isNameChanged, setIsNameChanged] = useState(false);
  const formats = useOCRFormats();

  const [currentFormat, setCurrentFormat] = useState(formats[0]);
  const fileTo = useMemo(() => currentFormat.format, [currentFormat]);

  const { handleUploadFile } = useFileUploadAndOCR({
    service: { ...service, to: fileTo },
  });

  const handleSetCurrentFormat = (format: OCRExportFormat) => {
    setCurrentFormat(format);
  };

  const handleCancel = (type: string) => {
    dispatch(toggleModal({ visible: false }));
    dispatch(sendAnalyticEvent({ event: 'cancel_save_edited_file', data: { type } }));
  };

  const handleDownload = () => {
    dispatch(toggleModal({ visible: false }));
    const newFile = new File([file], `${filename}${fileFormat}`, {
      type: file.type,
    });

    dispatch(
      sendAnalyticEvent({
        event: 'select_type_tap',
        data: {
          features_name: EXPORT_FORMATS_FEATURE_NAME_MAP[currentFormat.format],
          name_changed: isNameChanged,
        },
      })
    );

    handleUploadFile(newFile, currentFormat.format, `${filename}${fileFormat}`);
  };

  useEffect(() => {
    dispatch(sendAnalyticEvent({ event: 'save_edited_file_modal_view' }));
    const fileFormat = getFileFormatFromFile(file);

    setFileFormat(fileFormat || '.pdf');
    setFilename(file.name.replace(new RegExp(fileFormat + '$'), ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalLayout>
      <Container>
        <CloseIcon onClick={() => handleCancel('close')} src={closeImagePath} alt="" />
        <Title>{t('choose_format_modal.description')}</Title>
        <FormatsContainer>
          {formats?.map((item, index) => (
            <FormatItem
              key={`modal-format-item-${index + 1}`}
              current={currentFormat.format === item.format}
              onClick={() => handleSetCurrentFormat(item)}
            >
              <FormatLabel>
                <LazyLoadImage
                  src={
                    currentFormat.format === item.format ? radioButtonCheckPath : radioButtonPath
                  }
                  alt="radiobutton"
                  width={22}
                  height={22}
                />
                {item.label}
              </FormatLabel>
              <FormatDescription>{item.description}</FormatDescription>
            </FormatItem>
          ))}
        </FormatsContainer>
        <InputContainer>
          <CommonInput
            label={t('global.file_name')}
            placeholder={t('global.file_name_input_label')}
            value={filename}
            onChange={(value: string) => {
              setFilename(value);
              setIsNameChanged(true);
            }}
          />
        </InputContainer>
        <ButtonsContainer>
          <CommonButton onClick={() => handleCancel('cancel')} type="tertiary">
            {t('global.cancel_action')}
          </CommonButton>
          <CommonButton disabled={!filename} onClick={handleDownload} type="primary">
            {t('global.download_file_button')}
          </CommonButton>
        </ButtonsContainer>
      </Container>
    </ModalLayout>
  );
};

export default ChooseFormatAndParseTextModal;
