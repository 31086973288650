import { FC } from 'react';

import legalImgPath from 'assets/legal-icon.svg';
import logoPath from 'assets/logo-thebestpdf.svg';
import planetImgPath from 'assets/planet-icon.svg';
import userGroupImgPath from 'assets/user-group-icon.svg';
import CommonLink from 'components/common/link';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { useChangeLanguage, useCurrentLanguage } from 'hooks/useCustomNavigate';
import { useLegalAddress } from 'hooks/useLegalAddress';
import { languageLabels, languages, SupportedLanguage } from 'i18n';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch } from 'react-redux';
import {
  Container,
  CopyrightContainer,
  DesktopContainer,
  Divider,
  LanguageLink,
  LinkItem,
  LinksContainer,
  LinksGroupTitle,
  Logo,
} from './styles';

const Footer: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const changeLanguage = useChangeLanguage();
  const currentLanguage = useCurrentLanguage();
  const { legalName, legalAddress } = useLegalAddress();

  const handleClick = (button?: string) => {
    if (button) dispatch(sendAnalyticEvent({ event: 'footer_tap', data: { button } }));
    window.scrollTo(0, 0);
  };

  const handleChangeLanguage = (e: any, lng: SupportedLanguage) => {
    e.preventDefault();
    changeLanguage(lng);
  };

  return (
    <Container>
      <DesktopContainer>
        <LinksContainer>
          <LinkItem to="/" onClick={() => handleClick()}>
            <Logo alt="TheBestPDF footer logo" src={logoPath} width="160px" height="30px" />
          </LinkItem>
        </LinksContainer>
        <LinksContainer>
          <LinksGroupTitle>
            <LazyLoadImage alt="user group" src={userGroupImgPath} width="24px" height="24px" />
            {t('footer.our_team_column_title')}
          </LinksGroupTitle>
          <CommonLink
            size="18px"
            type="tertiary"
            onClick={() => handleClick('about_us')}
            to="/about-us"
          >
            {t('footer.about_us_page_link')}
          </CommonLink>
          <CommonLink
            size="18px"
            type="tertiary"
            onClick={() => handleClick('contact_us')}
            to="/contact-us"
          >
            {t('footer.contact_us_page_link')}
          </CommonLink>
        </LinksContainer>
        <LinksContainer>
          <LinksGroupTitle>
            <LazyLoadImage alt="legal" src={legalImgPath} width="24px" height="24px" />
            {t('footer.legal_information_column_title')}
          </LinksGroupTitle>
          <CommonLink
            size="18px"
            type="tertiary"
            onClick={() => handleClick('subscription_terms')}
            to="/subscription-terms"
          >
            {t('global.subscription_terms_page_link')}
          </CommonLink>
          <CommonLink
            size="18px"
            type="tertiary"
            onClick={() => handleClick('terms_and_conds')}
            to="/terms"
          >
            {t('global.terms_and_conditions_page_link')}
          </CommonLink>
          <CommonLink
            size="18px"
            type="tertiary"
            onClick={() => handleClick('privacy_policy')}
            to="/money-back-policy"
          >
            {t('global.money_back_page_link')}
          </CommonLink>
          <CommonLink
            size="18px"
            type="tertiary"
            onClick={() => handleClick('money_back_policy')}
            to="/privacy-policy"
          >
            {t('global.privacy_policy_page_link')}
          </CommonLink>
        </LinksContainer>
        <LinksContainer>
          <LinksGroupTitle>
            <LazyLoadImage alt="planet" src={planetImgPath} width="24px" height="24px" />
            {t('global.languages_list_group_title')}
          </LinksGroupTitle>
          {languages.map((lang) => (
            <LanguageLink key={lang} onClick={(e: any) => handleChangeLanguage(e, lang)}>
              {languageLabels[lang]}
              {currentLanguage === lang && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="6"
                  viewBox="0 0 11 8"
                  fill="none"
                >
                  <path
                    d="M10.1686 0.808132L10.1612 0.799286L10.1533 0.791026C9.9336 0.563535 9.57844 0.56289 9.358 0.789087L4.16211 6.00772L1.65639 3.48452C1.43596 3.25816 1.08068 3.25875 0.860956 3.48629C0.646348 3.70854 0.646348 4.05938 0.860956 4.28163L0.860935 4.28165L0.863895 4.28463L3.50907 6.94827C3.68503 7.12954 3.92421 7.21992 4.1473 7.21992C4.38885 7.21992 4.61222 7.12682 4.78539 6.94841L10.1163 1.59421C10.3528 1.37353 10.3505 1.02796 10.1686 0.808132Z"
                    fill="#212E45"
                    stroke="#212E45"
                    strokeWidth="0.6"
                  />
                </svg>
              )}
            </LanguageLink>
          ))}
        </LinksContainer>
      </DesktopContainer>
      <Divider />

      <CopyrightContainer>
        © TheBestPDF. All rights reserved 2024.
        {`${legalName}, ${legalAddress}.`}
      </CopyrightContainer>
    </Container>
  );
};

export default Footer;
