import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import closeImagePath from 'assets/close-icon.svg';
import pageImagePath from 'assets/page.svg';
import ModalLayout from 'components/modals/baseModal';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { deleteDocumentById } from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector } from 'data/selectors/modals';
import useKeyPress from 'hooks/useKeyPress';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CloseIcon } from '../baseModal/styles';
import {
  ButtonCancel,
  ButtonContainer,
  ButtonDelete,
  Container,
  ImageContainer,
  Title,
} from './styles';

const DeleteDocumentModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { documentId } = useSelector(modalOptionsSelector);

  useEffect(() => {
    dispatch(sendAnalyticEvent({ event: 'delete_ﬁle_modal_view' }));
  }, [dispatch]);

  const handleDeleteFile = () => {
    dispatch(deleteDocumentById(documentId));
    dispatch(toggleModal({ visible: false }));
    dispatch(sendAnalyticEvent({ event: 'delete_ﬁle_confirm_tap' }));
  };

  useKeyPress({ targetKey: 'Enter', onKeyPressDown: handleDeleteFile });

  const handleClose = () => {
    dispatch(toggleModal({ visible: false }));
    dispatch(sendAnalyticEvent({ event: 'delete_ﬁle_cancel_tap' }));
  };

  return (
    <ModalLayout customHandleKeyPress={handleClose}>
      <Container>
        <CloseIcon onClick={handleClose} src={closeImagePath} alt="" />
        <ImageContainer>
          <LazyLoadImage src={pageImagePath} alt="" />
        </ImageContainer>
        <Title>{t('global.are_sure_want_delete_file')}</Title>
        <ButtonContainer>
          <ButtonCancel
            width="190px"
            type="tertiary"
            onClick={() => dispatch(toggleModal({ visible: false }))}
          >
            {t('global.cancel_action')}
          </ButtonCancel>
          <ButtonDelete width="190px" type="danger" onClick={handleDeleteFile}>
            {t('global.delete_file')}
          </ButtonDelete>
        </ButtonContainer>
      </Container>
    </ModalLayout>
  );
};

export default DeleteDocumentModal;
