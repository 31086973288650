import Excel2PdfIcon from 'assets/converterIcons/excel_2_pdf.svg';
import {
  default as Jpg2PdfIcon,
  default as Png2PdfIcon,
} from 'assets/converterIcons/jpg_2_pdf.svg';
import Pdf2JpgIcon from 'assets/converterIcons/pdf_2_jpg.svg';
import Pdf2PngIcon from 'assets/converterIcons/pdf_2_png.svg';
import Pdf2PptxIcon from 'assets/converterIcons/pdf_2_pptx.svg';
import Pdf2WordIcon from 'assets/converterIcons/pdf_2_word.svg';
import Pdf2XlsIcon from 'assets/converterIcons/pdf_2_xls.svg';
import Pptx2PdfIcon from 'assets/converterIcons/pptx_2_pdf.svg';
import Word2PdfIcon from 'assets/converterIcons/word_2_pdf.svg';
import { Trans } from 'react-i18next';

import { EServiceType, IService } from 'ts/interfaces/services/service';

export const converterServicesList = (t: any): IService[] => {
  return [
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-word',
      name: t('landing.pdf_to_word.name'),
      nameKey: 'landing.pdf_to_word.name',
      title: t('landing.pdf_to_word.h1.title'),
      aiTitle: t('landing.pdf_to_word.ai_title'),
      description: t('landing.pdf_to_word.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.pdf_to_word.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.pdf_to_word.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.pdf_to_word.landing_hero.hero_benefit_3')}</Trans>,
      ],
      aboutSection: {
        title: t('landing.pdf_to_word.howTo.h2'),
        items: [
          t('landing.pdf_to_word.howTo.first'),
          t('landing.pdf_to_word.howTo.second'),
          t('landing.pdf_to_word.howTo.third'),
        ],
      },
      ctaTitle: t('landing.pdf_to_word.CTA.title'),
      buttonLabel: t('landing.pdf_to_word.CTA.buttonLabel'),
      meta: {
        title: t('landing.pdf_to_word.meta.title'),
        description: t('landing.pdf_to_word.meta.description'),
      },
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'DOCX',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-png',
      name: t('landing.pdf_to_png.name'),
      nameKey: 'landing.pdf_to_png.name',
      title: t('landing.pdf_to_png.h1.title'),
      aiTitle: t('landing.pdf_to_png.ai_title'),
      description: t('landing.pdf_to_png.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.pdf_to_png.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.pdf_to_png.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.pdf_to_png.landing_hero.hero_benefit_3')}</Trans>,
      ],
      aboutSection: {
        title: t('landing.pdf_to_png.howTo.h2'),
        items: [
          t('landing.pdf_to_png.howTo.first'),
          t('landing.pdf_to_png.howTo.second'),
          t('landing.pdf_to_png.howTo.third'),
        ],
      },
      ctaTitle: t('landing.pdf_to_png.CTA.title'),
      buttonLabel: t('landing.pdf_to_png.CTA.buttonLabel'),
      meta: {
        title: t('landing.pdf_to_png.meta.title'),
        description: t('landing.pdf_to_png.meta.description'),
      },
      icon: Pdf2PngIcon,
      from: 'PDF',
      to: 'PNG',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-jpg',
      name: t('landing.pdf_to_jpg.name'),
      nameKey: 'landing.pdf_to_jpg.name',
      title: t('landing.pdf_to_jpg.h1.title'),
      aiTitle: t('landing.pdf_to_jpg.ai_title'),
      description: t('landing.pdf_to_jpg.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.pdf_to_jpg.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.pdf_to_jpg.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.pdf_to_jpg.landing_hero.hero_benefit_3')}</Trans>,
      ],
      aboutSection: {
        title: t('landing.pdf_to_jpg.howTo.h2'),
        items: [
          t('landing.pdf_to_jpg.howTo.first'),
          t('landing.pdf_to_jpg.howTo.second'),
          t('landing.pdf_to_jpg.howTo.third'),
        ],
      },
      ctaTitle: t('landing.pdf_to_jpg.CTA.title'),
      buttonLabel: t('landing.pdf_to_jpg.CTA.buttonLabel'),
      meta: {
        title: t('landing.pdf_to_jpg.meta.title'),
        description: t('landing.pdf_to_jpg.meta.description'),
      },
      icon: Pdf2JpgIcon,
      from: 'PDF',
      to: 'JPG',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-epub',
      name: t('landing.pdf_to_epub.name'),
      nameKey: 'landing.pdf_to_epub.name',
      title: t('landing.pdf_to_epub.h1.title'),
      aiTitle: t('landing.pdf_to_epub.ai_title'),
      description: t('landing.pdf_to_epub.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.pdf_to_epub.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.pdf_to_epub.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.pdf_to_epub.landing_hero.hero_benefit_3')}</Trans>,
      ],
      aboutSection: {
        title: t('landing.pdf_to_epub.howTo.h2'),
        items: [
          t('landing.pdf_to_epub.howTo.first'),
          t('landing.pdf_to_epub.howTo.second'),
          t('landing.pdf_to_epub.howTo.third'),
        ],
      },
      ctaTitle: t('landing.pdf_to_epub.CTA.title'),
      buttonLabel: t('landing.pdf_to_epub.CTA.buttonLabel'),
      meta: {
        title: t('landing.pdf_to_epub.meta.title'),
        description: t('landing.pdf_to_epub.meta.description'),
      },
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'EPUB',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-excel',
      name: t('landing.pdf_to_excel.name'),
      nameKey: 'landing.pdf_to_excel.name',
      title: t('landing.pdf_to_excel.h1.title'),
      aiTitle: t('landing.pdf_to_excel.ai_title'),
      description: t('landing.pdf_to_excel.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.pdf_to_excel.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.pdf_to_excel.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.pdf_to_excel.landing_hero.hero_benefit_3')}</Trans>,
      ],
      aboutSection: {
        title: t('landing.pdf_to_excel.howTo.h2'),
        items: [
          t('landing.pdf_to_excel.howTo.first'),
          t('landing.pdf_to_excel.howTo.second'),
          t('landing.pdf_to_excel.howTo.third'),
        ],
      },
      ctaTitle: t('landing.pdf_to_excel.CTA.title'),
      buttonLabel: t('landing.pdf_to_excel.CTA.buttonLabel'),
      meta: {
        title: t('landing.pdf_to_excel.meta.title'),
        description: t('landing.pdf_to_excel.meta.description'),
      },
      icon: Pdf2XlsIcon,
      from: 'PDF',
      to: 'XLS',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-pptx',
      name: t('landing.pdf_to_pptx.name'),
      nameKey: 'landing.pdf_to_pptx.name',
      title: t('landing.pdf_to_pptx.h1.title'),
      aiTitle: t('landing.pdf_to_pptx.ai_title'),
      description: t('landing.pdf_to_pptx.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.pdf_to_pptx.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.pdf_to_pptx.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.pdf_to_pptx.landing_hero.hero_benefit_3')}</Trans>,
      ],
      aboutSection: {
        title: t('landing.pdf_to_pptx.howTo.h2'),
        items: [
          t('landing.pdf_to_pptx.howTo.first'),
          t('landing.pdf_to_pptx.howTo.second'),
          t('landing.pdf_to_pptx.howTo.third'),
        ],
      },
      ctaTitle: t('landing.pdf_to_pptx.CTA.title'),
      buttonLabel: t('landing.pdf_to_pptx.CTA.buttonLabel'),
      meta: {
        title: t('landing.pdf_to_pptx.meta.title'),
        description: t('landing.pdf_to_pptx.meta.description'),
      },
      icon: Pdf2PptxIcon,
      from: 'PDF',
      to: 'PPTX',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/word-to-pdf',
      name: t('landing.word_to_pdf.name'),
      nameKey: 'landing.word_to_pdf.name',
      title: t('landing.word_to_pdf.h1.title'),
      aiTitle: t('landing.word_to_pdf.ai_title'),
      description: t('landing.word_to_pdf.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.word_to_pdf.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.word_to_pdf.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.word_to_pdf.landing_hero.hero_benefit_3')}</Trans>,
      ],
      aboutSection: {
        title: t('landing.word_to_pdf.howTo.h2'),
        items: [
          t('landing.word_to_pdf.howTo.first'),
          t('landing.word_to_pdf.howTo.second'),
          t('landing.word_to_pdf.howTo.third'),
        ],
      },
      ctaTitle: t('landing.word_to_pdf.CTA.title'),
      buttonLabel: t('landing.word_to_pdf.CTA.buttonLabel'),
      meta: {
        title: t('landing.word_to_pdf.meta.title'),
        description: t('landing.word_to_pdf.meta.description'),
      },
      icon: Word2PdfIcon,
      from: 'DOC',
      to: 'PDF',
      availableFormats: '.doc, .docx, .rtf, .jpg, .jpeg, .txt, .png, .odt, .pages',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/png-to-pdf',
      name: t('landing.png_to_pdf.name'),
      nameKey: 'landing.png_to_pdf.name',
      title: t('landing.png_to_pdf.h1.title'),
      aiTitle: t('landing.png_to_pdf.ai_title'),
      description: t('landing.png_to_pdf.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.png_to_pdf.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.png_to_pdf.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.png_to_pdf.landing_hero.hero_benefit_3')}</Trans>,
      ],
      aboutSection: {
        title: t('landing.png_to_pdf.howTo.h2'),
        items: [
          t('landing.png_to_pdf.howTo.first'),
          t('landing.png_to_pdf.howTo.second'),
          t('landing.png_to_pdf.howTo.third'),
        ],
      },
      ctaTitle: t('landing.png_to_pdf.CTA.title'),
      buttonLabel: t('landing.png_to_pdf.CTA.buttonLabel'),
      meta: {
        title: t('landing.png_to_pdf.meta.title'),
        description: t('landing.png_to_pdf.meta.description'),
      },
      icon: Jpg2PdfIcon,
      from: 'PNG',
      to: 'PDF',
      availableFormats: '.png, .jpeg, .jpg, .webp, .heic, .jfif, .odt',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/jpg-to-pdf',
      name: t('landing.jpg_to_pdf.name'),
      nameKey: 'landing.jpg_to_pdf.name',
      title: t('landing.jpg_to_pdf.h1.title'),
      aiTitle: t('landing.jpg_to_pdf.ai_title'),
      description: t('landing.jpg_to_pdf.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.jpg_to_pdf.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.jpg_to_pdf.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.jpg_to_pdf.landing_hero.hero_benefit_3')}</Trans>,
      ],
      aboutSection: {
        title: t('landing.jpg_to_pdf.howTo.h2'),
        items: [
          t('landing.jpg_to_pdf.howTo.first'),
          t('landing.jpg_to_pdf.howTo.second'),
          t('landing.jpg_to_pdf.howTo.third'),
        ],
      },
      ctaTitle: t('landing.jpg_to_pdf.CTA.title'),
      buttonLabel: t('landing.jpg_to_pdf.CTA.buttonLabel'),
      meta: {
        title: t('landing.jpg_to_pdf.meta.title'),
        description: t('landing.jpg_to_pdf.meta.description'),
      },
      icon: Png2PdfIcon,
      from: 'JPG',
      to: 'PDF',
      availableFormats: '.jpg, .jpeg, .png, .heic, .webp, .jfif',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/epub-to-pdf',
      name: t('landing.epub_to_pdf.name'),
      nameKey: 'landing.epub_to_pdf.name',
      title: t('landing.epub_to_pdf.h1.title'),
      aiTitle: t('landing.epub_to_pdf.ai_title'),
      description: t('landing.epub_to_pdf.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.epub_to_pdf.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.epub_to_pdf.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.epub_to_pdf.landing_hero.hero_benefit_3')}</Trans>,
      ],
      aboutSection: {
        title: t('landing.epub_to_pdf.howTo.h2'),
        items: [
          t('landing.epub_to_pdf.howTo.first'),
          t('landing.epub_to_pdf.howTo.second'),
          t('landing.epub_to_pdf.howTo.third'),
        ],
      },
      ctaTitle: t('landing.epub_to_pdf.CTA.title'),
      buttonLabel: t('landing.epub_to_pdf.CTA.buttonLabel'),
      meta: {
        title: t('landing.epub_to_pdf.meta.title'),
        description: t('landing.epub_to_pdf.meta.description'),
      },
      icon: Pdf2WordIcon,
      from: 'EPUB',
      to: 'PDF',
      availableFormats: '.epub',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/excel-to-pdf',
      name: t('landing.excel_to_pdf.name'),
      nameKey: 'landing.excel_to_pdf.name',
      title: t('landing.excel_to_pdf.h1.title'),
      aiTitle: t('landing.excel_to_pdf.ai_title'),
      description: t('landing.excel_to_pdf.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.excel_to_pdf.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.excel_to_pdf.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.excel_to_pdf.landing_hero.hero_benefit_3')}</Trans>,
      ],
      aboutSection: {
        title: t('landing.excel_to_pdf.howTo.h2'),
        items: [
          t('landing.excel_to_pdf.howTo.first'),
          t('landing.excel_to_pdf.howTo.second'),
          t('landing.excel_to_pdf.howTo.third'),
        ],
      },
      ctaTitle: t('landing.excel_to_pdf.CTA.title'),
      buttonLabel: t('landing.excel_to_pdf.CTA.buttonLabel'),
      meta: {
        title: t('landing.excel_to_pdf.meta.title'),
        description: t('landing.excel_to_pdf.meta.description'),
      },
      icon: Excel2PdfIcon,
      from: 'XLS',
      to: 'PDF',
      availableFormats: '.xls, .xlsx, .csv, .txt, .rtf, .ods, .odt',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pptx-to-pdf',
      name: t('landing.pptx_to_pdf.name'),
      nameKey: 'landing.pptx_to_pdf.name',
      title: t('landing.pptx_to_pdf.h1.title'),
      aiTitle: t('landing.pptx_to_pdf.ai_title'),
      description: t('landing.pptx_to_pdf.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.pptx_to_pdf.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.pptx_to_pdf.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.pptx_to_pdf.landing_hero.hero_benefit_3')}</Trans>,
      ],
      aboutSection: {
        title: t('landing.pptx_to_pdf.howTo.h2'),
        items: [
          t('landing.pptx_to_pdf.howTo.first'),
          t('landing.pptx_to_pdf.howTo.second'),
          t('landing.pptx_to_pdf.howTo.third'),
        ],
      },
      ctaTitle: t('landing.pptx_to_pdf.CTA.title'),
      buttonLabel: t('landing.pptx_to_pdf.CTA.buttonLabel'),
      meta: {
        title: t('landing.pptx_to_pdf.meta.title'),
        description: t('landing.pptx_to_pdf.meta.description'),
      },
      icon: Pptx2PdfIcon,
      from: 'PPTX',
      to: 'PDF',
      availableFormats: '.pptx, .ppt',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/image-to-pdf',
      name: t('landing.image_to_pdf.name'),
      nameKey: 'landing.image_to_pdf.name',
      title: t('landing.image_to_pdf.h1.title'),
      aiTitle: t('landing.image_to_pdf.ai_title'),
      description: t('landing.image_to_pdf.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.image_to_pdf.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.image_to_pdf.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.image_to_pdf.landing_hero.hero_benefit_3')}</Trans>,
      ],
      aboutSection: {
        title: t('landing.image_to_pdf.howTo.h2'),
        items: [
          t('landing.image_to_pdf.howTo.first'),
          t('landing.image_to_pdf.howTo.second'),
          t('landing.image_to_pdf.howTo.third'),
        ],
      },
      ctaTitle: t('landing.image_to_pdf.CTA.title'),
      buttonLabel: t('landing.image_to_pdf.CTA.buttonLabel'),
      meta: {
        title: t('landing.image_to_pdf.meta.title'),
        description: t('landing.image_to_pdf.meta.description'),
      },
      icon: Png2PdfIcon,
      from: 'Image',
      to: 'PDF',
      // TODO: Removed unsupported images
      // availableFormats: '.jpg, .jpeg, .png, .gif, .svg, .webp, .bmp, .eps',
      availableFormats: '.jpg, .jpeg, .png, .gif, .webp, .bmp',
    },
  ];
};
