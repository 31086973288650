import MergePdfIcon from 'assets/converterIcons/merge_pdf.svg';
import { Trans } from 'react-i18next';
import { EServiceType } from 'ts/interfaces/services/service';

export const mergeImagesPageService = (t: any) => {
  return {
    serviceType: EServiceType.MERGER,
    path: '/merge-images',
    name: t('landing.merge_images.name'),
    nameKey: 'landing.merge_images.name',
    title: t('landing.merge_images.h1.title'),
    description: t('landing.merge_images.h1.subtitle'),
    heroFeatures: [
      <Trans>{t('landing.merge_images.landing_hero.hero_benefit_1')}</Trans>,
      <Trans>{t('landing.merge_images.landing_hero.hero_benefit_2')}</Trans>,
      <Trans>{t('landing.merge_images.landing_hero.hero_benefit_3')}</Trans>,
    ],
    icon: MergePdfIcon,
    from: 'Image',
    to: 'PDF',
    availableFormats: '.jpg, .jpeg, .png, .gif, .bmp, .webp',
    aboutSection: {
      title: t('landing.merge_images.howTo.h2'),
      items: [
        t('landing.merge_images.howTo.first'),
        t('landing.merge_images.howTo.second'),
        t('landing.merge_images.howTo.third'),
      ],
    },
    ctaTitle: t('landing.merge_images.CTA.title'),
    buttonLabel: t('landing.merge_images.CTA.buttonLabel'),
    meta: {
      title: t('landing.merge_images.meta.title'),
      description: t('landing.merge_images.meta.description'),
    },
  };
};
