import Payment, { ClientSdkInstance, InitConfig } from '@solidgate/react-sdk';
import queryString from 'query-string';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getUserSubscription, setOrderData } from 'data/actions/subscriptions';
import { selectedPlanSelector } from 'data/selectors/subscriptions';
import { useCustomNavigate } from 'hooks/useCustomNavigate';

import CommonButton from 'components/common/button';
import {
  handleOnFail,
  handleOnFormMounted,
  handleOnSubmit,
  handleOnSuccessEvent,
} from 'components/paymentForm/callbacks';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { toggleModal } from 'data/actions/modals';
import { isLatAmUserSelector, isReversedCurrencyPositionSelector } from 'data/selectors/user';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { EModalTypes } from 'ts/enums/modal.types';
import { browser } from 'utils/deviceTypeDetecter';
import {
  ButtonContainer,
  Container,
  FormHeader,
  InputGroupSmall,
  LabeledDivider,
  MobilePriceContainer,
  MobilePriceText,
  MobileText,
  PaymentFormWrapper,
  PaymentMethodButton,
  PaymentStyles,
} from './styles';

const isSafari = ['Safari', 'Mobile Safari'].includes(browser?.name || '');

const PaymentForm: FC<{
  merchantData: InitConfig['merchantData'];
  styles?: InitConfig['styles'];
  formParams?: InitConfig['formParams'];
  width?: string;
  handleTryAgain?: unknown;
  paypalScript?: string | undefined | null;
  hideLabel?: boolean;
  testB?: string;
}> = ({ merchantData, handleTryAgain, width, paypalScript, hideLabel, testB }) => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const { t } = useTranslation();
  const appleContainerRef = useRef(null);
  const googleContainerRef = useRef(null);
  const selectedPlan = useSelector(selectedPlanSelector) || getJsonFomStorage('selectedPlan');
  const isLatAmUser = useSelector(isLatAmUserSelector);
  const isReversedCurrencyPosition = useSelector(isReversedCurrencyPositionSelector);

  const [solidForm, setSolidForm] = useState<ClientSdkInstance | null>(null);
  const [isApplePayButtonReady, setIsApplePayButtonReady] = useState(false);

  const handleShowError = (errorCode: string, errorMessage?: string) => {
    dispatch(
      toggleModal({
        visible: true,
        type: EModalTypes.PAYMENT_ERROR,
        options: { handleTryAgain, errorMessage, errorCode },
      })
    );
  };

  const handleOnReadyPaymentInstance = (form: ClientSdkInstance) => {
    setSolidForm(form);
  };

  const handleNavigate = useCallback(() => {
    if (window.location.search) {
      const parsed = queryString.parse(window.location.search);
      if (parsed?.['documentId'])
        return navigate(`/checkout/success?documentId=${parsed?.['documentId']}`, {
          replace: true,
        });
    }
    navigate('/checkout/success', { replace: true });
  }, [navigate]);

  const handlePay = () => {
    // window.scrollTo(0, 0); // Scrolls to the top of the page
    solidForm?.submit();
  };

  const handleGetUserSubscription = useCallback(() => {
    let countRetries = 0;
    let interval: any;

    const onSuccessCallback = (res: any) => {
      if (res?.id) {
        if (interval) clearInterval(interval);
        dispatch(toggleModal({ visible: false }));
        handleNavigate();
        window.scrollTo(0, 0); // Scrolls to the top of the page
      }
    };

    interval = setInterval(() => {
      countRetries += 1;
      if (countRetries < 10) dispatch(getUserSubscription(onSuccessCallback));
      else {
        dispatch(toggleModal({ visible: false }));
        clearInterval(interval);
        handleNavigate();
        window.scrollTo(0, 0); // Scrolls to the top of the page
      }
    }, 3000);
  }, [dispatch, handleNavigate]);

  const handleOnSuccess = (event: any, paymentMethod?: string) => {
    if (event?.order || event.detail?.data?.order)
      dispatch(setOrderData(event?.order || event.detail?.data?.order));
    dispatch(toggleModal({ visible: true, type: EModalTypes.THANK_YOU_PAYMENT }));
    handleOnSuccessEvent({
      paymentMethod: paymentMethod || event.entity,
      orderId: event?.order?.order_id,
      productId: selectedPlan?.id,
      price: selectedPlan?.price,
      fullPrice: selectedPlan.fullPrice,
    });
    setTimeout(() => handleGetUserSubscription(), 3000);
  };

  const handlePayPal = () => {
    const elem = document.getElementById('paypal-button');

    if (elem) {
      dispatch(
        sendAnalyticEvent({
          event: 'paypal_button_init',
          data: {
            merchantData: JSON.stringify(merchantData),
          },
        })
      );
      // @ts-ignore
      elem.addEventListener(
        'order-started-processing',
        function () {
          // interactor.showPopup('converting', {})
        },
        false
      );

      // @ts-ignore
      elem.addEventListener(
        'order-processed',
        function (event) {
          // @ts-ignore
          const status = event.detail?.data?.order?.status;

          // @NOTE logic to prevent payment methods from re-init
          // reason - solid sdk fires "decline" event with 6.01 error code
          // when user close paypal modal while its loadings
          // @ts-ignore
          const errorCode = event.detail?.data?.error?.code;
          if (errorCode && errorCode === '6.01') {
            return;
          }
          // @ts-ignore
          if (status === 'approved') {
            handleOnSuccess(event, 'paypal-vault');
            return;
          }
          // @ts-ignore
          if (status === 'fail' || errorCode) {
            handleOnFail(event, 'paypal-vault', selectedPlan?.id, handleShowError);
          }
        },
        false
      );

      // @ts-ignore
      elem.addEventListener(
        'button-ready',
        function () {
          dispatch(
            sendAnalyticEvent({
              event: 'payment_form_view',
              data: { paymentMethod: 'paypal-vault' },
            })
          );
        },
        false
      );

      // @ts-ignore
      elem.addEventListener(
        'button-error',
        function () {
          dispatch(
            sendAnalyticEvent({
              event: 'payment_form_error',
            })
          );
        },
        false
      );

      // @ts-ignore
      elem.addEventListener(
        'button-click',
        function () {
          handleOnSubmit('paypal-vault', selectedPlan?.id);
        },
        false
      );
    }
  };

  useEffect(() => {
    if (paypalScript && !isLatAmUser) {
      handlePayPal();
    }
  }, [paypalScript]); // eslint-disable-line

  return (
    <Container>
      {!hideLabel && <FormHeader>{t('checkout_page.select_payment_method')}</FormHeader>}
      <ButtonContainer is_test={testB}>
        {!isLatAmUser && (
          <PaymentMethodButton style={{ padding: 0 }}>
            <div id="paypal-button"></div>
          </PaymentMethodButton>
        )}
        <PaymentMethodButton
          hideButton={isSafari.toString()}
          ref={googleContainerRef}
          id="googlePayId"
        />
        <PaymentMethodButton
          className={isSafari && isApplePayButtonReady ? '' : 'hidden'}
          hideButton={(!isSafari || !isApplePayButtonReady).toString()}
          ref={appleContainerRef}
          id="applePayId"
        />
      </ButtonContainer>
      <LabeledDivider is_test={testB}>
        <span>{t('checkout_page.or_pay_with_card')}</span>
      </LabeledDivider>

      <PaymentFormWrapper>
        <Payment
          merchantData={merchantData}
          width={width}
          googlePayContainerRef={googleContainerRef}
          applePayContainerRef={appleContainerRef}
          formParams={{
            submitButtonText: t('checkout_page.submit_button'),
            titleText: t('checkout_page.payment_details_label'),
            emailLabel: t('checkout_page.email_label'),
            emailPlaceholder: t('checkout_page.email_placeholder'),
            cardCvvLabel: t('checkout_page.card_cvv_label'),
            cardNumberLabel: t('checkout_page.card_number_label'),
            cardExpiryDateLabel: t('checkout_page.expiry_date_label'),
            cardExpiryDatePlaceholder: 'MM/YY',
            cardNumberPlaceholder: '0000 0000 0000 0000',
            cardCvvPlaceholder: '***',
            cardBrands: [
              // @ts-ignore
              'mastercard',
              // @ts-ignore
              'maestro',
              // @ts-ignore
              'visa',
              // @ts-ignore
              'american-express',
            ],
          }}
          onSuccess={(event) => handleOnSuccess(event)}
          onMounted={(event) => {
            handleOnFormMounted(event.entity, isSafari);
            if (event.entity === 'applebtn') {
              setIsApplePayButtonReady(true);
            }
          }}
          onSubmit={(event) => handleOnSubmit(event.entity, selectedPlan?.id)}
          onFail={(event) => handleOnFail(event, event.entity, selectedPlan?.id, handleShowError)}
          onError={(event) => handleOnFail(event, 'Card', selectedPlan?.id, handleShowError)}
          styles={PaymentStyles}
          onReadyPaymentInstance={handleOnReadyPaymentInstance}
          googlePayButtonParams={{
            containerId: 'googlePayId',
            color: 'black',
            type: 'buy',
          }}
        />
      </PaymentFormWrapper>

      <InputGroupSmall style={{ borderTop: '1px solid var(--Stroke-Color, #DFE4EA)' }}>
        <MobilePriceContainer>
          <MobileText>
            {t('checkout_page.selected_plan_label')}: {t(selectedPlan?.name || '')}
          </MobileText>
          <MobilePriceText>
            {isReversedCurrencyPosition ? (
              <>
                {selectedPlan?.isYear ? selectedPlan?.fullPrice : selectedPlan?.price}
                {selectedPlan?.currencySymbol || '$'}
              </>
            ) : (
              <>
                {selectedPlan?.currencySymbol || '$'}
                {selectedPlan?.isYear ? selectedPlan?.fullPrice : selectedPlan?.price}
              </>
            )}
          </MobilePriceText>
        </MobilePriceContainer>
        <CommonButton type="primary" onClick={handlePay}>
          {t('checkout_page.submit_payment_button')}
        </CommonButton>
      </InputGroupSmall>
    </Container>
  );
};

export default PaymentForm;
