import { useFeature } from '@growthbook/growthbook-react';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { GrowthBookTestType, LocalStorageProperties } from 'ts/enums/growthbook';

export const useLongTrials = () => {
  const abTests = getJsonFomStorage('abTests');
  const discountTestABC = useFeature(GrowthBookTestType.LONG_TRIALS);

  if (abTests?.includes(LocalStorageProperties.LONG_TRIALS_B))
    return LocalStorageProperties.LONG_TRIALS_B as const;

  if (discountTestABC?.experiment?.key && discountTestABC?.experimentResult?.value) {
    return `${discountTestABC?.experiment?.key}_${discountTestABC?.experimentResult?.value}` as
      | LocalStorageProperties.LONG_TRIALS_A
      | LocalStorageProperties.LONG_TRIALS_B;
  }

  return LocalStorageProperties.LONG_TRIALS_A as const;
};
