import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import closeImagePath from 'assets/close-icon.svg';
import CommonButton from 'components/common/button';
import CommonInput from 'components/common/input';
import openNotification from 'components/commonNotification';
import { toggleModal } from 'data/actions/modals';
import { sendForgotPassword } from 'data/actions/user';
import useKeyPress from 'hooks/useKeyPress';
import { EModalTypes } from 'ts/enums/modal.types';
import { ENotification } from 'ts/interfaces/common/notification';

import { sendAnalyticEvent } from 'data/actions/analytics';
import { CloseIconForgotPassword, ForgotPasswordContainer, InputContainer, Title } from './styles';

const ForgotPassword: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [errors, setErrors] = useState({ email: '' });

  useEffect(() => {
    dispatch(sendAnalyticEvent({ event: 'recover_pass_modal_view' }));
  }, [dispatch]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setErrors((prev) => ({
        ...prev,
        email: t('global.please_enter_valid_email_error'),
      }));
      return false;
    }
    setErrors((prev) => ({ ...prev, email: '' }));
    return true;
  };

  const onFailed = () => {
    openNotification({
      message: t('auth_form.email_does_not_exist_error'),
      duration: 5,
      type: ENotification.ERROR,
      description: '',
    });
  };

  const onSuccess = () => {
    dispatch(
      toggleModal({
        visible: true,
        type: EModalTypes.CHECK_YOUR_EMAIL,
        options: { email },
      })
    );
  };

  const handleClose = () => {
    dispatch(sendAnalyticEvent({ event: 'recover_pass_close_tap' }));
    dispatch(toggleModal({ visible: false }));
  };

  const handleResetPassword = () => {
    const isValidEmail = validateEmail(email);
    if (!isValidEmail) return;

    dispatch(sendAnalyticEvent({ event: 'recover_pass_confirm_tap' }));
    dispatch(sendForgotPassword(email, onSuccess, onFailed));
    dispatch(toggleModal({ visible: false }));
  };

  useKeyPress({ targetKey: 'Enter', onKeyPressDown: handleResetPassword });

  return (
    <ForgotPasswordContainer>
      <CloseIconForgotPassword onClick={handleClose} src={closeImagePath} alt="" />
      <Title>{t('auth_form.enter_your_email_address')}</Title>

      <InputContainer>
        <CommonInput
          onChange={(value: string) => setEmail(value)}
          value={email}
          error={errors.email}
          placeholder={t('auth_form.email_address_input_placeholder')}
          label={t('auth_form.email_address_input_placeholder')}
          name="email"
          type="email"
        />
      </InputContainer>

      <CommonButton
        type="primary"
        style={{ marginTop: '14px' }}
        disabled={!email?.length}
        onClick={handleResetPassword}
      >
        {t('auth_form.confirm_email_button')}
      </CommonButton>
    </ForgotPasswordContainer>
  );
};

export default ForgotPassword;
