import { useFeature } from '@growthbook/growthbook-react';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { GrowthBookTestType, LocalStorageProperties } from 'ts/enums/growthbook';

export const useCardholder = () => {
  const abTests = getJsonFomStorage('abTests');
  const cardholderTestAB = useFeature(GrowthBookTestType.CARDHOLDER);

  if (abTests?.includes(LocalStorageProperties.CARDHOLDER_B))
    return LocalStorageProperties.CARDHOLDER_B as const;

  if (cardholderTestAB?.experiment?.key && cardholderTestAB?.experimentResult?.value) {
    return `${cardholderTestAB?.experiment?.key}_${cardholderTestAB?.experimentResult?.value}` as
      | LocalStorageProperties.CARDHOLDER_A
      | LocalStorageProperties.CARDHOLDER_B;
  }

  return LocalStorageProperties.LONG_TRIALS_A as const;
};
