import { AxiosError } from 'axios';
import { checkRecaptcha } from 'helpers/checkRecaptcha';
import { setRecentlyUsedActions } from 'helpers/recentlyUsedActions';
import { AnyAction, Dispatch } from 'redux';
import { Analytics } from 'services/analytics';
import { EReduxTypes } from 'ts/enums/redux.types';
import { ServerErrorResponse } from 'ts/interfaces/common/network';
import {
  ICompressDocumentDto,
  IConvertDocumentDto,
  IEditDocumentDto,
  IMergeDocumentDto,
  IOCRDocumentDto,
  ISplitDocumentDto,
} from 'ts/interfaces/documents/document';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { IFormService, IService } from 'ts/interfaces/services/service';
import { formatFileSize } from 'utils/formatFileSize';
import { transliterate } from 'utils/transliterate';

declare global {
  interface Window {
    grecaptcha: any;
  }
}

export const getUploadLink =
  ({
    filename,
    onSuccess,
    onFailed,
    service,
  }: {
    filename: string | string[];
    onSuccess?: unknown;
    onFailed?: unknown;
    service?: IService | IFormService;
  }) =>
  (dispatch: Dispatch<any>, getState: any): void => {
    const state = getState();

    const action: IReduxAction = {
      type: EReduxTypes.GET_UPLOAD_LINK,
      payload: {
        method: 'POST',
        endpoint: 'api/v1/files/upload-link',
        body: {
          filenames: typeof filename === 'string' ? [filename] : filename,
        },
      },
      onSuccess: (res) => {
        if (typeof onSuccess === 'function') onSuccess(res);
        if (service) {
          setRecentlyUsedActions(service as IService);
        }
      },
      onFailed: (error?: AxiosError<ServerErrorResponse>) => {
        if (typeof onFailed === 'function') onFailed(error);
      },
    };

    if (
      window?.grecaptcha?.enterprise &&
      state?.user?.userData?.count_uploaded_files &&
      state?.user?.userData?.count_uploaded_files >= 100
    ) {
      checkRecaptcha()
        .then((res: any) => {
          if (action.payload) {
            action.payload.params = {
              captcha_token: res,
              captcha_action: 'USER_TENTH_FILE',
            };

            dispatch(action);
          }
        })
        .catch(() => dispatch(action));
    } else dispatch(action);
  };

export const downloadDocument = (
  documentId: string,
  onFailed?: unknown,
  onSuccess?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.DOWNLOAD_DOCUMENT,
    payload: {
      method: 'GET',
      endpoint: `api/v1/files/download/${documentId}`,
    },
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
    onSuccess: (res) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const updateDownloadProgress = (progress: number): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.UPDATE_DOWNLOAD_PROGRESS,
    data: {
      progress,
    },
  };
  return action;
};

export const updateEditFilename = (filename: string): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.UPDATE_EDIT_FILENAME,
    data: {
      filename,
    },
  };
  return action;
};

export const setConvertDocumentData = ({
  filename,
  key,
  size,
  pagesCount = 1,
  from,
  to,
  url,
  serviceType,
  fileList,
}: IConvertDocumentDto): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_CONVERT_DOCUMENT_DATA,
    data: {
      filename,
      size,
      key,
      pagesCount,
      from,
      to: to || 'PNG',
      url,
      serviceType,
      fileList,
    },
  };
  return action;
};

export const updateEditDocumentData = (data: { from?: string; to?: string }): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.UPDATE_EDIT_DOCUMENT_DATA,
    data,
  };
  return action;
};

export const setEditDocumentData = ({
  filename,
  key,
  size,
  pagesCount = 1,
}: IConvertDocumentDto | IEditDocumentDto): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_EDIT_DOCUMENT_DATA,
    data: {
      filename,
      size,
      key,
      pagesCount,
    },
  };
  return action;
};

export const getParsedTextFromFile = (
  fileId: string,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_OCRED_FILE,
    payload: {
      method: 'POST',
      endpoint: `api/v1/files/${fileId}/ocred`,
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
  };
  return action;
};

export const getConvertedFile = (
  fileId: string,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_CONVERTED_FILE,
    payload: {
      method: 'POST',
      endpoint: `api/v1/files/${fileId}/converted`,
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
  };
  return action;
};

export const getOptimizedFile = (fileId: string, onSuccess?: unknown): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_OPTIMIZED_FILE,
    payload: {
      method: 'POST',
      endpoint: `api/v1/files/${fileId}/optimized`,
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const convertDocument = (
  { filename, key, size, pagesCount = 1, from, to, url }: IConvertDocumentDto,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CONVERT_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint:
        from === 'PDF' && to === 'PDF'
          ? 'api/v1/editor/file-uploaded'
          : 'api/v1/converter/file-uploaded',
      body: {
        filename,
        size,
        key,
        pagesCount,
        ...(!(from === 'PDF' && to === 'PDF') && { from, to: to || 'PNG' }),
      },
    },
    data: {
      filename,
      size,
      key,
      pagesCount,
      ...(!(from === 'PDF' && to === 'PDF') && { from, to: to || 'PNG', url }),
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
      void Analytics.sendEvent({
        event: 'file_upload_status',
        data: {
          status: 'fail',
          place: 'additional',
          errorCode: res?.response?.status,
          size: formatFileSize(size),
          accurate_size: size / 1000000,
          file_format: from,
          is_validation_error: 'false',
        },
      });
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const getDocuments = (onSuccess?: unknown): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_DOCUMENTS,
    payload: {
      method: 'GET',
      endpoint: `api/v1/files`,
    },
    delayLoading: 500,
    components: ['get_documents'],
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const getDocumentById = (documentId: string, onSuccess?: unknown): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_DOCUMENT_BY_ID,
    payload: {
      method: 'GET',
      endpoint: `api/v1/files/download/${documentId}`,
    },
    data: {
      documentId,
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const deleteDocumentById = (documentId: string, onSuccess?: unknown): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.DELETE_DOCUMENT_BY_ID,
    payload: {
      method: 'DELETE',
      endpoint: `api/v1/files/${documentId}`,
    },
    data: {
      documentId,
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const convertedFile = (
  documentId: string,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CONVERT_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint: `api/v1/files/${documentId}/converted`,
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
  };
  return action;
};

export const setPDFfileContent = ({
  document,
  onSuccess,
  onFailed,
}: {
  document: ArrayBuffer | string | null;
  onSuccess?: unknown;
  onFailed?: unknown;
}) => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_PDF_FILE_CONTENT,
    data: {
      document,
    },
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const downloadAndConvertToBuffer = (
  documentId: string,
  onFailed?: unknown,
  onSuccess?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.DOWNLOAD_AND_CONVERT_TO_BUFFER,
    payload: {
      method: 'GET',
      endpoint: `api/v1/files/download/${documentId}`,
    },
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') {
        fetch(res.url)
          .then((file) => {
            return file.arrayBuffer();
          })
          .then((buffer) => {
            onSuccess(buffer);
          });
      }
    },
  };
  return action;
};

export const uploadEditDocument = (
  { filename, key, size, pagesCount = 1 }: IEditDocumentDto,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.UPLOAD_EDIT_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/editor/file-uploaded',
      body: {
        filename,
        size,
        key,
        pagesCount,
      },
    },
    data: {
      filename,
      size,
      key,
      pagesCount,
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed();
      void Analytics.sendEvent({
        event: 'file_upload_status',
        data: {
          status: 'fail',
          place: 'additional',
          errorCode: res?.response?.status,
          size: formatFileSize(size),
          accurate_size: size / 1000000,
          file_format: '.pdf',
          is_validation_error: 'false',
        },
      });
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};
export const parseTextFromDocument = (
  { filename, key, size, exportFormat }: IOCRDocumentDto,
  fileFormat: string,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.PARSE_TEXT_FROM_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/ocr/file-uploaded',
      body: {
        filename,
        size,
        key,
        exportFormat,
      },
    },
    data: {
      filename,
      size,
      key,
      exportFormat,
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed();
      void Analytics.sendEvent({
        event: 'file_upload_status',
        data: {
          status: 'fail',
          place: 'additional',
          errorCode: res?.response?.status,
          size: formatFileSize(size),
          accurate_size: size / 1000000,
          file_format: fileFormat,
          is_validation_error: 'false',
        },
      });
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const convertExistingDocument = ({
  fileId,
  to,
  from,
  onSuccess,
  onFailed,
}: {
  fileId: string;
  to: string;
  from: string;
  onSuccess?: unknown;
  onFailed?: unknown;
}): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CONVERT_EXISTING_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/converter/existing',
      body: {
        from,
        to: to || 'PNG',
        fileId,
      },
    },
    data: {
      from,
      to: to || 'PNG',
      fileId,
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const saveEditDocument = ({
  fileId,
  file,
  onSuccess,
  onFailed,
}: {
  fileId: string;
  file: File;
  onSuccess?: unknown;
  onFailed?: unknown;
}): AnyAction => {
  const formData = new FormData();
  formData.append('originalFileId', fileId);
  formData.append('file', file, transliterate(file.name));

  const action: IReduxAction = {
    type: EReduxTypes.SAVE_EDIT_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/editor/save',
      body: formData,
      customHeaders: {
        'Content-Type': 'multipart/form-data',
      },
    },
    components: ['save_edit_document'],
    data: {
      fileId,
    },
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const compressDocument = (
  { filename, key, size, pagesCount = 1 }: ICompressDocumentDto,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CONVERT_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/optimize/file-uploaded',
      body: {
        filename,
        size,
        key,
        pagesCount,
      },
    },
    data: {
      filename,
      size,
      key,
      pagesCount,
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed();
      void Analytics.sendEvent({
        event: 'file_upload_status',
        data: {
          status: 'fail',
          place: 'additional',
          errorCode: res?.response?.status,
          size: formatFileSize(size),
          accurate_size: size / 1000000,
          file_format: '.pdf',
          is_validation_error: 'false',
        },
      });
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const splitExistingDocument = ({
  fileId,
  onSuccess,
  onFailed,
}: {
  fileId: string;
  onSuccess?: unknown;
  onFailed?: unknown;
}): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SPLIT_EXISTING_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/split/existing',
      body: {
        fileId,
      },
    },
    data: {
      fileId,
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const splitDocument = ({
  filename,
  size,
  key,
  pagesCount,
  onSuccess,
  onFailed,
}: ISplitDocumentDto): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SPLIT_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/split/file-uploaded',
      body: {
        filename,
        size,
        key,
        pagesCount,
      },
    },
    data: {
      filename,
      size,
      key,
      pagesCount,
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
      void Analytics.sendEvent({
        event: 'file_upload_status',
        data: {
          status: 'fail',
          place: 'additional',
          errorCode: res?.response?.status,
          size: formatFileSize(size),
          accurate_size: size / 1000000,
          file_format: '.pdf',
          is_validation_error: 'false',
        },
      });
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const getSplittedFile = (fileId: string, onSuccess?: unknown): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_SPLITTED_FILE,
    payload: {
      method: 'POST',
      endpoint: `api/v1/files/${fileId}/splitted`,
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const getMergedFile = (
  fileId: string,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_MERGED_FILE,
    payload: {
      method: 'GET',
      endpoint: `api/v1/files/${fileId}/merged`,
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
  };
  return action;
};

export const setMergeDocumentData = (fileList: any[]): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_MERGE_DOCUMENT_DATA,
    data: fileList,
  };
  return action;
};

export const updateMergeDocumentImage = (
  index: number,
  thumbnail: string,
  onSuccess?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.UPDATE_MERGE_DOCUMENT_IMAGE,
    data: { index, thumbnail },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const pushMergeDocument = (
  file: { file: File; thumbnail?: string | null },
  onSuccess?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.PUSH_MERGE_DOCUMENT_DATA,
    data: file,
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const mergeDocuments = (
  data: IMergeDocumentDto[],
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.MERGE_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/merge/files-uploaded',
      body: {
        files: data,
      },
    },
    data: data,
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed();
      Analytics.sendEvent({ event: 'complete_merge_error' });
      void Analytics.sendEvent({
        event: 'file_upload_status',
        data: {
          status: 'fail',
          place: 'additional',
          errorCode: res?.response?.status,
          file_format: '.pdf',
          is_validation_error: 'false',
        },
      });
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};
// https://notifications.pdfguru.com/notifications/subscribe/converting/a4a57ef8-ebfc-4476-80ca-298b1f01718a
// https://notifications.thebestpdf.com/notifications/subscribe/converting/1aba1d58-8e21-4a8c-ad6e-be4dc8f2711f

export const convertingEvent = (userId: string): AnyAction => {
  console.log('convertingEvent', userId);
  const action: IReduxAction = {
    type: EReduxTypes.CONVERTING_EVENT,
    event: {
      path: `notifications/subscribe/converting/${userId}`,
    },
  };
  return action;
};
