import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import closeImagePath from 'assets/close-icon.svg';
import radioButtonCheckPath from 'assets/radiobutton-check.svg';
import radioButtonPath from 'assets/radiobutton.svg';
import CommonButton from 'components/common/button';
import CommonInput from 'components/common/input';
import ModalLayout from 'components/modals/baseModal';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector } from 'data/selectors/modals';
import useFileUploadAndConvert from 'hooks/useFileUploadAndConvert';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSearchParams } from 'react-router-dom';
import { CloseIcon } from '../baseModal/styles';
import { formatsDictionary } from './formatsList';
import {
  ButtonsContainer,
  Container,
  Description,
  FormatItem,
  FormatLabel,
  FormatsContainer,
  InputContainer,
  Title,
} from './styles';

const ChooseFormatAndConvertModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { service, file } = useSelector(modalOptionsSelector);
  const [searchParams] = useSearchParams();
  const convertTo = searchParams.get('to');

  const [filename, setFilename] = useState<string>('');
  const [fileFrom, setFileFrom] = useState<string>('PDF');
  const [fileTo, setFileTo] = useState<string>(convertTo ?? 'DOCX');
  const [formatsList, setFormatsList] = useState<
    { format: string; label: string; to: string; icon?: any }[]
  >(formatsDictionary['.pdf']);
  const [currentFormat, setCurrentFormat] = useState<{
    format: string;
    label: string;
    to: string;
    icon?: any;
  }>(formatsList[0]);

  const { handleUploadFile } = useFileUploadAndConvert({
    service: { ...service, from: fileFrom, to: fileTo },
  });

  const handleSetCurrentFormat = (format: {
    format: string;
    label: string;
    to: string;
    icon?: any;
  }) => {
    setFileTo(format.to);
    setCurrentFormat(format);
  };

  useEffect(() => {
    if (!file) return;
    const extension: string = file?.name?.split('.').pop() || [];
    if (!extension) return;

    const newFormatsList = formatsDictionary[`.${extension}`] || formatsDictionary['default'];

    setFilename(file?.name?.replace(`.${extension}`, ''));
    setFileFrom(extension?.toUpperCase());
    setFormatsList(newFormatsList);
    setCurrentFormat(newFormatsList[0]);
    setFileTo(newFormatsList[0].to);
  }, [file]);

  const handleCancel = () => {
    dispatch(toggleModal({ visible: false }));
  };

  const handleDownload = () => {
    dispatch(toggleModal({ visible: false }));
    const newFile = new File([file], `${filename}.${fileFrom?.toLocaleLowerCase()}`, {
      type: file.type,
    });
    handleUploadFile(newFile);
  };

  return (
    <ModalLayout>
      <Container>
        <CloseIcon onClick={handleCancel} src={closeImagePath} alt="" />
        <Title>{t('choose_format_modal.title')}</Title>
        <Description>{t('choose_format_modal.description')}</Description>
        <FormatsContainer>
          {formatsList?.map((item, index) => (
            <FormatItem
              key={`modal-format-item-${index + 1}`}
              current={currentFormat.format === item.format}
              onClick={() => handleSetCurrentFormat(item)}
            >
              <LazyLoadImage
                src={currentFormat.format === item.format ? radioButtonCheckPath : radioButtonPath}
                alt="radiobutton"
              />
              <FormatLabel>{item.label}</FormatLabel>
              <LazyLoadImage className="format-icon" src={item.icon} alt="format icon" />
            </FormatItem>
          ))}
        </FormatsContainer>
        <InputContainer>
          <CommonInput
            label={t('global.file_name')}
            placeholder={t('global.file_name_input_label')}
            value={filename}
            onChange={(value: string) => {
              setFilename(value);
            }}
          />
        </InputContainer>
        <ButtonsContainer>
          <CommonButton onClick={handleCancel} type="tertiary">
            {t('global.cancel_action')}
          </CommonButton>
          <CommonButton disabled={!filename} onClick={handleDownload} type="primary">
            {t('global.download_file_button')}
          </CommonButton>
        </ButtonsContainer>
      </Container>
    </ModalLayout>
  );
};

export default ChooseFormatAndConvertModal;
