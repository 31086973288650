import Pdf2PngIcon from 'assets/converterIcons/pdf_2_png.svg';
import { EServiceType, IService } from 'ts/interfaces/services/service';

export const mainPageService = (t: any): IService => {
  return {
    serviceType: EServiceType.CONVERTOR,
    path: '/pdf-to-png',
    name: t('main_page.name'),
    nameKey: 'main_page.name',
    title: t('main_page.h1.title'),
    description: t('main_page.h1.subtitle'),
    aboutSection: {
      title: t('main_page.features.h2'),
      items: [t('main_page.features.1'), t('main_page.features.2'), t('main_page.features.3')],
    },
    ctaTitle: t('main_page.slogan.h2'),
    meta: {
      title: t('main_page.meta.title'),
      description: t('main_page.meta.description'),
    },
    icon: Pdf2PngIcon,
    from: 'PDF',
    to: 'PNG',
    availableFormats: '.pdf',
  };
};
