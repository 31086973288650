import Word2PdfIcon from 'assets/converterIcons/word_2_pdf.svg';
import BackgroundDS11HeroImg from 'assets/forms/ds11MainBg.png';
import BackgroundDS82HeroImg from 'assets/forms/ds82MainBg.png';
import FormIcon1 from 'assets/forms/formIcon1.svg';
import FormIcon2 from 'assets/forms/formIcon2.svg';
import FormIcon3 from 'assets/forms/formIcon3.svg';
import HotToIcon from 'assets/forms/howToForm.svg';
import BackgroundW9HeroImg from 'assets/forms/w9MainBg.png';
import BackgroundCMS1500HeroImg from 'assets/forms/cms1500MainBg.png';
import BackgroundW4HeroImg from 'assets/forms/w4MainBg.png';
import BackgroundI864HeroImg from 'assets/forms/i864MainBg.png';
import BackgroundI9HeroImg from 'assets/forms/i9MainBg.png';

import formDS11 from 'assets/forms/ds11.pdf';
import formDS82 from 'assets/forms/ds82.pdf';
import formW9 from 'assets/forms/w9.pdf';
import formCMS1500 from 'assets/forms/cms-1500.pdf';
import formW4 from 'assets/forms/w-4.pdf';
import formI864 from 'assets/forms/i-864.pdf';
import formI9 from 'assets/forms/i-9.pdf';

import { EServiceType, IFormService } from 'ts/interfaces/services/service';

export const formServicesList = (t: any): IFormService[] => {
  return [
    {
      serviceType: EServiceType.EDITOR,
      pagesCount: 6,
      path: '/form-w9',
      filePath: formW9,
      fileName: 'w9.pdf',
      name: t('landing.form_w9.name'),
      nameKey: 'landing.form_w9.name',
      title: t('landing.form_w9.h1.title'),
      description: t('landing.form_w9.h1.subtitle'),
      caption: t('landing.form_w9.caption'),
      from: 'PDF',
      to: 'PDF',
      mainBackground: BackgroundW9HeroImg,
      aboutSection: {
        title: t('landing.form_w9.about_section.title'),
        items: [
          {
            label: t('landing.form_w9.about_section.items.0.label'),
            description: t('landing.form_w9.about_section.items.0.description'),
            icon: FormIcon1,
            color: '#3758F9',
          },
          {
            label: t('landing.form_w9.about_section.items.1.label'),
            description: t('landing.form_w9.about_section.items.1.description'),
            icon: FormIcon2,
            color: '#13C296',
          },
          {
            label: t('landing.form_w9.about_section.items.2.label'),
            description: t('landing.form_w9.about_section.items.2.description'),
            icon: FormIcon3,
            color: '#3758F9',
          },
        ],
      },
      whatIsSection: {
        title: t('landing.form_w9.what_is_section.title'),
        text: t('landing.form_w9.what_is_section.text'),
      },
      howToSection: {
        title: t('landing.form_w9.how_to_section.title'),
        background: '#3758F9',
        icon: HotToIcon,
        items: [
          t('landing.form_w9.how_to_section.items.0'),
          t('landing.form_w9.how_to_section.items.1'),
          t('landing.form_w9.how_to_section.items.2'),
          t('landing.form_w9.how_to_section.items.3'),
          t('landing.form_w9.how_to_section.items.4'),
          t('landing.form_w9.how_to_section.items.5'),
          t('landing.form_w9.how_to_section.items.6'),
        ],
      },
      whoIsSection: {
        title: t('landing.form_w9.who_is_section.title'),
        items: [
          t('landing.form_w9.who_is_section.items.0'),
          t('landing.form_w9.who_is_section.items.1'),
        ],
      },
      faqSection: {
        title: t('landing.form_w9.faq_section.title'),
        items: [
          {
            title: t('landing.form_w9.faq_section.items.0.title'),
            text: t('landing.form_w9.faq_section.items.0.text'),
          },
          {
            title: t('landing.form_w9.faq_section.items.1.title'),
            text: t('landing.form_w9.faq_section.items.1.text'),
          },
          {
            title: t('landing.form_w9.faq_section.items.2.title'),
            text: t('landing.form_w9.faq_section.items.2.text'),
          },
          {
            title: t('landing.form_w9.faq_section.items.3.title'),
            text: t('landing.form_w9.faq_section.items.3.text'),
          },
        ],
      },
      contentNav: [
        t('landing.form_w9.content_nav.0'),
        t('landing.form_w9.content_nav.1'),
        t('landing.form_w9.content_nav.2'),
        t('landing.form_w9.content_nav.3'),
      ],
      ctaTitles: [t('landing.form_w9.cta_titles.0'), t('landing.form_w9.cta_titles.1')],
      meta: {
        title: t('main_page.meta.title'),
        description: t('main_page.meta.description'),
      },
      icon: Word2PdfIcon,
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.EDITOR,
      pagesCount: 6,
      path: '/form-ds11',
      filePath: formDS11,
      fileName: 'ds11.pdf',
      name: t('landing.form_ds11.name'),
      nameKey: 'landing.form_ds11.name',
      title: t('landing.form_ds11.h1.title'),
      description: t('landing.form_ds11.h1.subtitle'),
      caption: t('landing.form_ds11.caption'),
      from: 'PDF',
      to: 'PDF',
      mainBackground: BackgroundDS11HeroImg,
      aboutSection: {
        title: t('landing.form_ds11.about_section.title'),
        items: [
          {
            label: t('landing.form_ds11.about_section.items.0.label'),
            description: t('landing.form_ds11.about_section.items.0.description'),
            icon: FormIcon1,
            color: '#3758F9',
          },
          {
            label: t('landing.form_ds11.about_section.items.1.label'),
            description: t('landing.form_ds11.about_section.items.1.description'),
            icon: FormIcon2,
            color: '#13C296',
          },
          {
            label: t('landing.form_ds11.about_section.items.2.label'),
            description: t('landing.form_ds11.about_section.items.2.description'),
            icon: FormIcon3,
            color: '#3758F9',
          },
        ],
      },
      whatIsSection: {
        title: t('landing.form_ds11.what_is_section.title'),
        text: t('landing.form_ds11.what_is_section.text'),
      },
      howToSection: {
        title: t('landing.form_ds11.how_to_section.title'),
        background: '#3758F9',
        icon: HotToIcon,
        items: [
          t('landing.form_ds11.how_to_section.items.0'),
          t('landing.form_ds11.how_to_section.items.1'),
          t('landing.form_ds11.how_to_section.items.2'),
          t('landing.form_ds11.how_to_section.items.3'),
          t('landing.form_ds11.how_to_section.items.4'),
          t('landing.form_ds11.how_to_section.items.5'),
          t('landing.form_ds11.how_to_section.items.6'),
        ],
      },
      whoIsSection: {
        title: t('landing.form_ds11.who_is_section.title'),
        items: [
          t('landing.form_ds11.who_is_section.items.0'),
          t('landing.form_ds11.who_is_section.items.1'),
        ],
      },
      faqSection: {
        title: t('landing.form_ds11.faq_section.title'),
        items: [
          {
            title: t('landing.form_ds11.faq_section.items.0.title'),
            text: t('landing.form_ds11.faq_section.items.0.text'),
          },
          {
            title: t('landing.form_ds11.faq_section.items.1.title'),
            text: t('landing.form_ds11.faq_section.items.1.text'),
          },
          {
            title: t('landing.form_ds11.faq_section.items.2.title'),
            text: t('landing.form_ds11.faq_section.items.2.text'),
          },
          {
            title: t('landing.form_ds11.faq_section.items.3.title'),
            text: t('landing.form_ds11.faq_section.items.3.text'),
          },
        ],
      },
      contentNav: [
        t('landing.form_ds11.content_nav.0'),
        t('landing.form_ds11.content_nav.1'),
        t('landing.form_ds11.content_nav.2'),
        t('landing.form_ds11.content_nav.3'),
      ],
      ctaTitles: [t('landing.form_ds11.cta_titles.0'), t('landing.form_ds11.cta_titles.1')],
      meta: {
        title: t('main_page.meta.title'),
        description: t('main_page.meta.description'),
      },
      icon: Word2PdfIcon,
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.EDITOR,
      pagesCount: 6,
      path: '/form-ds82',
      filePath: formDS82,
      fileName: 'ds82.pdf',
      name: t('landing.form_ds82.name'),
      nameKey: 'landing.form_ds82.name',
      title: t('landing.form_ds82.h1.title'),
      description: t('landing.form_ds82.h1.subtitle'),
      caption: t('landing.form_ds82.caption'),
      from: 'PDF',
      to: 'PDF',
      mainBackground: BackgroundDS82HeroImg,
      aboutSection: {
        title: t('landing.form_ds82.about_section.title'),
        items: [
          {
            label: t('landing.form_ds82.about_section.items.0.label'),
            description: t('landing.form_ds82.about_section.items.0.description'),
            icon: FormIcon1,
            color: '#3758F9',
          },
          {
            label: t('landing.form_ds82.about_section.items.1.label'),
            description: t('landing.form_ds82.about_section.items.1.description'),
            icon: FormIcon2,
            color: '#13C296',
          },
          {
            label: t('landing.form_ds82.about_section.items.2.label'),
            description: t('landing.form_ds82.about_section.items.2.description'),
            icon: FormIcon3,
            color: '#3758F9',
          },
        ],
      },
      whatIsSection: {
        title: t('landing.form_ds82.what_is_section.title'),
        text: t('landing.form_ds82.what_is_section.text'),
      },
      howToSection: {
        title: t('landing.form_ds82.how_to_section.title'),
        background: '#3758F9',
        icon: HotToIcon,
        items: [
          t('landing.form_ds82.how_to_section.items.0'),
          t('landing.form_ds82.how_to_section.items.1'),
          t('landing.form_ds82.how_to_section.items.2'),
          t('landing.form_ds82.how_to_section.items.3'),
          t('landing.form_ds82.how_to_section.items.4'),
          t('landing.form_ds82.how_to_section.items.5'),
          t('landing.form_ds82.how_to_section.items.6'),
        ],
      },
      whoIsSection: {
        title: t('landing.form_ds82.who_is_section.title'),
        items: [
          t('landing.form_ds82.who_is_section.items.0'),
          t('landing.form_ds82.who_is_section.items.1'),
        ],
      },
      faqSection: {
        title: t('landing.form_ds82.faq_section.title'),
        items: [
          {
            title: t('landing.form_ds82.faq_section.items.0.title'),
            text: t('landing.form_ds82.faq_section.items.0.text'),
          },
          {
            title: t('landing.form_ds82.faq_section.items.1.title'),
            text: t('landing.form_ds82.faq_section.items.1.text'),
          },
          {
            title: t('landing.form_ds82.faq_section.items.2.title'),
            text: t('landing.form_ds82.faq_section.items.2.text'),
          },
          {
            title: t('landing.form_ds82.faq_section.items.3.title'),
            text: t('landing.form_ds82.faq_section.items.3.text'),
          },
        ],
      },
      contentNav: [
        t('landing.form_ds82.content_nav.0'),
        t('landing.form_ds82.content_nav.1'),
        t('landing.form_ds82.content_nav.2'),
        t('landing.form_ds82.content_nav.3'),
      ],
      ctaTitles: [t('landing.form_ds82.cta_titles.0'), t('landing.form_ds82.cta_titles.1')],
      meta: {
        title: t('main_page.meta.title'),
        description: t('main_page.meta.description'),
      },
      icon: Word2PdfIcon,
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.EDITOR,
      pagesCount: 4,
      path: '/form-cms-1500',
      filePath: formCMS1500,
      fileName: 'cms-1500.pdf',
      name: t('landing.form_cms_1500.name'),
      nameKey: 'landing.form_cms_1500.name',
      title: t('landing.form_cms_1500.h1.title'),
      description: t('landing.form_cms_1500.h1.subtitle'),
      caption: t('landing.form_cms_1500.caption'),
      from: 'PDF',
      to: 'PDF',
      mainBackground: BackgroundCMS1500HeroImg,
      aboutSection: {
        title: t('landing.form_cms_1500.about_section.title'),
        items: [
          {
            label: t('landing.form_cms_1500.about_section.items.0.label'),
            description: t('landing.form_cms_1500.about_section.items.0.description'),
            icon: FormIcon1,
            color: '#3758F9',
          },
          {
            label: t('landing.form_cms_1500.about_section.items.1.label'),
            description: t('landing.form_cms_1500.about_section.items.1.description'),
            icon: FormIcon2,
            color: '#13C296',
          },
          {
            label: t('landing.form_cms_1500.about_section.items.2.label'),
            description: t('landing.form_cms_1500.about_section.items.2.description'),
            icon: FormIcon3,
            color: '#3758F9',
          },
        ],
      },
      whatIsSection: {
        title: t('landing.form_cms_1500.what_is_section.title'),
        text: t('landing.form_cms_1500.what_is_section.text'),
      },
      howToSection: {
        title: t('landing.form_cms_1500.how_to_section.title'),
        background: '#3758F9',
        icon: HotToIcon,
        items: [
          t('landing.form_cms_1500.how_to_section.items.0'),
          t('landing.form_cms_1500.how_to_section.items.1'),
          t('landing.form_cms_1500.how_to_section.items.2'),
          t('landing.form_cms_1500.how_to_section.items.3'),
          t('landing.form_cms_1500.how_to_section.items.4'),
          t('landing.form_cms_1500.how_to_section.items.5'),
          t('landing.form_cms_1500.how_to_section.items.6'),
        ],
      },
      whoIsSection: {
        title: t('landing.form_cms_1500.who_is_section.title'),
        items: [
          t('landing.form_cms_1500.who_is_section.items.0'),
          t('landing.form_cms_1500.who_is_section.items.1'),
        ],
      },
      faqSection: {
        title: t('landing.form_cms_1500.faq_section.title'),
        items: [
          {
            title: t('landing.form_cms_1500.faq_section.items.0.title'),
            text: t('landing.form_cms_1500.faq_section.items.0.text'),
          },
          {
            title: t('landing.form_cms_1500.faq_section.items.1.title'),
            text: t('landing.form_cms_1500.faq_section.items.1.text'),
          },
          {
            title: t('landing.form_cms_1500.faq_section.items.2.title'),
            text: t('landing.form_cms_1500.faq_section.items.2.text'),
          },
          {
            title: t('landing.form_cms_1500.faq_section.items.3.title'),
            text: t('landing.form_cms_1500.faq_section.items.3.text'),
          },
        ],
      },
      contentNav: [
        t('landing.form_cms_1500.content_nav.0'),
        t('landing.form_cms_1500.content_nav.1'),
        t('landing.form_cms_1500.content_nav.2'),
        t('landing.form_cms_1500.content_nav.3'),
      ],
      ctaTitles: [t('landing.form_cms_1500.cta_titles.0'), t('landing.form_cms_1500.cta_titles.1')],
      meta: {
        title: t('main_page.meta.title'),
        description: t('main_page.meta.description'),
      },
      icon: Word2PdfIcon,
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.EDITOR,
      pagesCount: 4,
      path: '/form-w4',
      filePath: formW4,
      fileName: 'w-4.pdf',
      name: t('landing.form_w4.name'),
      nameKey: 'landing.form_w4.name',
      title: t('landing.form_w4.h1.title'),
      description: t('landing.form_w4.h1.subtitle'),
      caption: t('landing.form_w4.caption'),
      from: 'PDF',
      to: 'PDF',
      mainBackground: BackgroundW4HeroImg,
      aboutSection: {
        title: t('landing.form_w4.about_section.title'),
        items: [
          {
            label: t('landing.form_w4.about_section.items.0.label'),
            description: t('landing.form_w4.about_section.items.0.description'),
            icon: FormIcon1,
            color: '#3758F9',
          },
          {
            label: t('landing.form_w4.about_section.items.1.label'),
            description: t('landing.form_w4.about_section.items.1.description'),
            icon: FormIcon2,
            color: '#13C296',
          },
          {
            label: t('landing.form_w4.about_section.items.2.label'),
            description: t('landing.form_w4.about_section.items.2.description'),
            icon: FormIcon3,
            color: '#3758F9',
          },
        ],
      },
      whatIsSection: {
        title: t('landing.form_w4.what_is_section.title'),
        text: t('landing.form_w4.what_is_section.text'),
      },
      howToSection: {
        title: t('landing.form_w4.how_to_section.title'),
        background: '#3758F9',
        icon: HotToIcon,
        items: [
          t('landing.form_w4.how_to_section.items.0'),
          t('landing.form_w4.how_to_section.items.1'),
          t('landing.form_w4.how_to_section.items.2'),
          t('landing.form_w4.how_to_section.items.3'),
          t('landing.form_w4.how_to_section.items.4'),
          t('landing.form_w4.how_to_section.items.5'),
          t('landing.form_w4.how_to_section.items.6'),
        ],
      },
      whoIsSection: {
        title: t('landing.form_w4.who_is_section.title'),
        items: [
          t('landing.form_w4.who_is_section.items.0'),
          t('landing.form_w4.who_is_section.items.1'),
        ],
      },
      faqSection: {
        title: t('landing.form_w4.faq_section.title'),
        items: [
          {
            title: t('landing.form_w4.faq_section.items.0.title'),
            text: t('landing.form_w4.faq_section.items.0.text'),
          },
          {
            title: t('landing.form_w4.faq_section.items.1.title'),
            text: t('landing.form_w4.faq_section.items.1.text'),
          },
          {
            title: t('landing.form_w4.faq_section.items.2.title'),
            text: t('landing.form_w4.faq_section.items.2.text'),
          },
          {
            title: t('landing.form_w4.faq_section.items.3.title'),
            text: t('landing.form_w4.faq_section.items.3.text'),
          },
        ],
      },
      contentNav: [
        t('landing.form_w4.content_nav.0'),
        t('landing.form_w4.content_nav.1'),
        t('landing.form_w4.content_nav.2'),
        t('landing.form_w4.content_nav.3'),
      ],
      ctaTitles: [t('landing.form_w4.cta_titles.0'), t('landing.form_w4.cta_titles.1')],
      meta: {
        title: t('main_page.meta.title'),
        description: t('main_page.meta.description'),
      },
      icon: Word2PdfIcon,
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.EDITOR,
      pagesCount: 10,
      path: '/form-i864',
      filePath: formI864,
      fileName: 'i-864.pdf',
      name: t('landing.form_i864.name'),
      nameKey: 'landing.form_i864.name',
      title: t('landing.form_i864.h1.title'),
      description: t('landing.form_i864.h1.subtitle'),
      caption: t('landing.form_i864.caption'),
      from: 'PDF',
      to: 'PDF',
      mainBackground: BackgroundI864HeroImg,
      aboutSection: {
        title: t('landing.form_i864.about_section.title'),
        items: [
          {
            label: t('landing.form_i864.about_section.items.0.label'),
            description: t('landing.form_i864.about_section.items.0.description'),
            icon: FormIcon1,
            color: '#3758F9',
          },
          {
            label: t('landing.form_i864.about_section.items.1.label'),
            description: t('landing.form_i864.about_section.items.1.description'),
            icon: FormIcon2,
            color: '#13C296',
          },
          {
            label: t('landing.form_i864.about_section.items.2.label'),
            description: t('landing.form_i864.about_section.items.2.description'),
            icon: FormIcon3,
            color: '#3758F9',
          },
        ],
      },
      whatIsSection: {
        title: t('landing.form_i864.what_is_section.title'),
        text: t('landing.form_i864.what_is_section.text'),
      },
      howToSection: {
        title: t('landing.form_i864.how_to_section.title'),
        background: '#3758F9',
        icon: HotToIcon,
        items: [
          t('landing.form_i864.how_to_section.items.0'),
          t('landing.form_i864.how_to_section.items.1'),
          t('landing.form_i864.how_to_section.items.2'),
          t('landing.form_i864.how_to_section.items.3'),
          t('landing.form_i864.how_to_section.items.4'),
          t('landing.form_i864.how_to_section.items.5'),
          t('landing.form_i864.how_to_section.items.6'),
        ],
      },
      whoIsSection: {
        title: t('landing.form_i864.who_is_section.title'),
        items: [
          t('landing.form_i864.who_is_section.items.0'),
          t('landing.form_i864.who_is_section.items.1'),
        ],
      },
      faqSection: {
        title: t('landing.form_i864.faq_section.title'),
        items: [
          {
            title: t('landing.form_i864.faq_section.items.0.title'),
            text: t('landing.form_i864.faq_section.items.0.text'),
          },
          {
            title: t('landing.form_i864.faq_section.items.1.title'),
            text: t('landing.form_i864.faq_section.items.1.text'),
          },
          {
            title: t('landing.form_i864.faq_section.items.2.title'),
            text: t('landing.form_i864.faq_section.items.2.text'),
          },
          {
            title: t('landing.form_i864.faq_section.items.3.title'),
            text: t('landing.form_i864.faq_section.items.3.text'),
          },
        ],
      },
      contentNav: [
        t('landing.form_i864.content_nav.0'),
        t('landing.form_i864.content_nav.1'),
        t('landing.form_i864.content_nav.2'),
        t('landing.form_i864.content_nav.3'),
      ],
      ctaTitles: [t('landing.form_i864.cta_titles.0'), t('landing.form_i864.cta_titles.1')],
      meta: {
        title: t('main_page.meta.title'),
        description: t('main_page.meta.description'),
      },
      icon: Word2PdfIcon,
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.EDITOR,
      pagesCount: 4,
      path: '/form-i9',
      filePath: formI9,
      fileName: 'i-9.pdf',
      name: t('landing.form_i9.name'),
      nameKey: 'landing.form_i9.name',
      title: t('landing.form_i9.h1.title'),
      description: t('landing.form_i9.h1.subtitle'),
      caption: t('landing.form_i9.caption'),
      from: 'PDF',
      to: 'PDF',
      mainBackground: BackgroundI9HeroImg,
      aboutSection: {
        title: t('landing.form_i9.about_section.title'),
        items: [
          {
            label: t('landing.form_i9.about_section.items.0.label'),
            description: t('landing.form_i9.about_section.items.0.description'),
            icon: FormIcon1,
            color: '#3758F9',
          },
          {
            label: t('landing.form_i9.about_section.items.1.label'),
            description: t('landing.form_i9.about_section.items.1.description'),
            icon: FormIcon2,
            color: '#13C296',
          },
          {
            label: t('landing.form_i9.about_section.items.2.label'),
            description: t('landing.form_i9.about_section.items.2.description'),
            icon: FormIcon3,
            color: '#3758F9',
          },
        ],
      },
      whatIsSection: {
        title: t('landing.form_i9.what_is_section.title'),
        text: t('landing.form_i9.what_is_section.text'),
      },
      howToSection: {
        title: t('landing.form_i9.how_to_section.title'),
        background: '#3758F9',
        icon: HotToIcon,
        items: [
          t('landing.form_i9.how_to_section.items.0'),
          t('landing.form_i9.how_to_section.items.1'),
          t('landing.form_i9.how_to_section.items.2'),
          t('landing.form_i9.how_to_section.items.3'),
          t('landing.form_i9.how_to_section.items.4'),
          t('landing.form_i9.how_to_section.items.5'),
          t('landing.form_i9.how_to_section.items.6'),
        ],
      },
      whoIsSection: {
        title: t('landing.form_i9.who_is_section.title'),
        items: [
          t('landing.form_i9.who_is_section.items.0'),
          t('landing.form_i9.who_is_section.items.1'),
        ],
      },
      faqSection: {
        title: t('landing.form_i9.faq_section.title'),
        items: [
          {
            title: t('landing.form_i9.faq_section.items.0.title'),
            text: t('landing.form_i9.faq_section.items.0.text'),
          },
          {
            title: t('landing.form_i9.faq_section.items.1.title'),
            text: t('landing.form_i9.faq_section.items.1.text'),
          },
          {
            title: t('landing.form_i9.faq_section.items.2.title'),
            text: t('landing.form_i9.faq_section.items.2.text'),
          },
          {
            title: t('landing.form_i9.faq_section.items.3.title'),
            text: t('landing.form_i9.faq_section.items.3.text'),
          },
        ],
      },
      contentNav: [
        t('landing.form_i9.content_nav.0'),
        t('landing.form_i9.content_nav.1'),
        t('landing.form_i9.content_nav.2'),
        t('landing.form_i9.content_nav.3'),
      ],
      ctaTitles: [t('landing.form_i9.cta_titles.0'), t('landing.form_i9.cta_titles.1')],
      meta: {
        title: t('main_page.meta.title'),
        description: t('main_page.meta.description'),
      },
      icon: Word2PdfIcon,
      availableFormats: '.pdf',
    }
  ];
};
