import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { ISubscription } from 'ts/interfaces/subscriptions/subscriptions';

import {
  getInitialJapanesePlans,
  getInitialLatAmPlans,
  getInitialPlansLongTrial,
  getInitialPlansLongTrialLatam,
  getInitialPlansLongTrialJapanese,
  initialPlansRecurrentPrice,
} from 'helpers/generatePlanData';
import { getCurrencySymbol } from 'helpers/getCurrency';
import { countMonthlyPriceForAnnual } from 'utils/countMonthlyPriceForAnnual';

const initialState: ISubscription = {
  subscription: null,
  selectedPlan: null,
  plansRecurrent: initialPlansRecurrentPrice,
  plansLongTrial: getInitialPlansLongTrial(),
  plansLatAm: getInitialLatAmPlans(),
  plansJapanese: getInitialJapanesePlans(),
  plansLatAmLongTrial: getInitialPlansLongTrialLatam(),
  plansJapaneseLongTrial: getInitialPlansLongTrialJapanese(),
  offerPlan: null,
  offerPlanTestB: null,
  order: null,
};

const subscriptionsReducer = (state = initialState, action: IReduxAction): ISubscription | {} => {
  switch (action.type) {
    case EReduxTypes.SIGN_OUT: {
      return initialState;
    }

    case EReduxTypes.SET_SELECTED_PLAN_ID: {
      return {
        ...state,
        selectedPlan: action.data.plan,
      };
    }

    case EReduxTypes.SET_ORDER_DATA: {
      return {
        ...state,
        order: action.data,
      };
    }

    case EReduxTypes.GET_PLANS: {
      const newPlansRecurrent = [...state?.plansRecurrent];

      const newPlansLongTrial = [...state?.plansLongTrial];

      const newPlansLatAm = [...state?.plansLatAm];

      const newPlansJapanese = [...state?.plansJapanese];

      const newPlansLatAmLongTrial = [...state?.plansLatAmLongTrial];

      const newPlansJapaneseLongTrial = [...state?.plansJapaneseLongTrial];

      const productsList = (action?.payload || [])?.filter(
        (item: any) => item?.name?.includes('the_best_pdf') || item?.name?.includes('TheBestPDF')
      );

      for (let i = 0; i < state?.plansRecurrent?.length; i++) {
        const product = state?.plansRecurrent[i];
        const findProduct = productsList.find((item: any) => item.name === product?.slagName);

        if (findProduct) {
          product.currency = findProduct?.price?.currency || 'USD';

          if (findProduct?.price?.currency !== 'USD') {
            product.currencySymbol = getCurrencySymbol(findProduct?.price?.currency);

            if (findProduct?.price?.currency !== 'EUR') {
              product.price =
                findProduct?.price?.trial_price > 0
                  ? findProduct?.price?.trial_price / 100 || 0
                  : countMonthlyPriceForAnnual(findProduct?.price?.price) / 100;
              product.fullPrice = findProduct?.price?.price / 100 || 0;
            }
          }
        }
      }

      for (let i = 0; i < state?.plansLongTrial?.length; i++) {
        const product = state?.plansLongTrial[i];
        const findProduct = productsList.find((item: any) => item.name === product?.slagName);

        if (findProduct) {
          product.currency = findProduct?.price?.currency || 'USD';

          if (findProduct?.price?.currency !== 'USD') {
            product.currencySymbol = getCurrencySymbol(findProduct?.price?.currency);

            if (findProduct?.price?.currency !== 'EUR') {
              product.price =
                findProduct?.price?.trial_price > 0
                  ? findProduct?.price?.trial_price / 100 || 0
                  : countMonthlyPriceForAnnual(findProduct?.price?.price) / 100;
              product.fullPrice = findProduct?.price?.price / 100 || 0;
            }
          }
        }
      }

      for (let i = 0; i < state?.plansLatAm?.length; i++) {
        const product = state?.plansLatAm[i];
        const findProduct = productsList.find((item: any) => item.name === product?.slagName);

        if (findProduct) {
          product.price =
            findProduct?.price?.trial_price > 0
              ? findProduct?.price?.trial_price / 100 || 0
              : countMonthlyPriceForAnnual(findProduct?.price?.price) / 100;
          product.fullPrice = findProduct?.price?.price / 100 || 0;

          product.currency = findProduct?.price?.currency || 'USD';

          if (findProduct?.price?.currency !== 'USD') {
            product.currencySymbol = getCurrencySymbol(findProduct?.price?.currency);
          }
        }
      }

      for (let i = 0; i < state?.plansLatAmLongTrial?.length; i++) {
        const product = state?.plansLatAmLongTrial[i];
        const findProduct = productsList.find((item: any) => item.name === product?.slagName);

        if (findProduct) {
          product.price =
            findProduct?.price?.trial_price > 0
              ? findProduct?.price?.trial_price / 100 || 0
              : countMonthlyPriceForAnnual(findProduct?.price?.price) / 100;
          product.fullPrice = findProduct?.price?.price / 100 || 0;

          product.currency = findProduct?.price?.currency || 'USD';

          if (findProduct?.price?.currency !== 'USD') {
            product.currencySymbol = getCurrencySymbol(findProduct?.price?.currency);
          }
        }
      }

      for (let i = 0; i < state?.plansJapanese?.length; i++) {
        const product = state?.plansJapanese[i];
        const findProduct = productsList.find((item: any) => item.id === product?.id);

        if (findProduct) {
          product.price =
            findProduct?.price?.trial_price > 0
              ? findProduct?.price?.trial_price / 100 || 0
              : countMonthlyPriceForAnnual(findProduct?.price?.price) / 100;
          product.fullPrice = findProduct?.price?.price / 100 || 0;
        }
      }

      for (let i = 0; i < state?.plansJapaneseLongTrial?.length; i++) {
        const product = state?.plansJapaneseLongTrial[i];
        const findProduct = productsList.find((item: any) => item.id === product?.id);

        if (findProduct) {
          product.price =
            findProduct?.price?.trial_price > 0
              ? findProduct?.price?.trial_price / 100 || 0
              : countMonthlyPriceForAnnual(findProduct?.price?.price) / 100;
          product.fullPrice = findProduct?.price?.price / 100 || 0;
        }
      }

      return {
        ...state,
        plansRecurrent: newPlansRecurrent,
        plansLongTrial: newPlansLongTrial,
        plansLatAm: newPlansLatAm,
        plansLatAmLongTrial: newPlansLatAmLongTrial,
        plansJapanese: newPlansJapanese,
        plansJapaneseLongTrial: newPlansJapaneseLongTrial,
        offerPlan:
          (action?.payload || [])?.find(
            (item: any) => item?.name === 'the_best_pdf_monthly_49_95_70%_discount_14_99'
          ) || null,
        offerPlanTestB:
          (action?.payload || [])?.find(
            (item: any) => item?.name === 'the_best_pdf_monthly_39_95_70%_discount_local_PL_TR'
          ) || null,
      };
    }
    default:
      return state;
  }
};

export default subscriptionsReducer;
